import BigNumber from 'bignumber.js'
import buybackABI from 'config/abi/buyback.json'
import multicall from 'utils/multicall'
import { getBuybackAddress } from 'utils/addressHelpers'
import { getBuybackContract } from 'utils/contractHelpers'

const fetchPool = async () => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'buybackPrice',
    },
    {
      address: getBuybackAddress(),
      name: 'buybackAmount',
    },
    {
      address: getBuybackAddress(),
      name: 'totalApplyAmountThisRound',
    },
    {
      address: getBuybackAddress(),
      name: 'totalBuybackAmountProvided',
    },
    {
      address: getBuybackAddress(),
      name: 'applyFeeAmount',
    },
    {
      address: getBuybackAddress(),
      name: 'currentRound',
    },
    {
      address: getBuybackAddress(),
      name: 'roundStartTimestamp',
    },
    {
      address: getBuybackAddress(),
      name: 'roundPeriod',
    },
    {
      address: getBuybackAddress(),
      name: 'lockupPeriod',
    },
  ]

  const [
    buybackPrice,
    buybackAmount,
    totalApplyAmountThisRound,
    totalBuybackAmountProvided,
    applyFeeAmount,
    currentRound,
    roundStartTimestamp,
    roundPeriod,
    lockupPeriod,
  ] = await multicall(buybackABI, calls)
  return {
    buybackPrice: new BigNumber(buybackPrice).div(100).toNumber(),
    buybackAmount: new BigNumber(buybackAmount).toNumber(),
    totalApplyAmountThisRound: new BigNumber(totalApplyAmountThisRound).toNumber(),
    totalBuybackAmountProvided: new BigNumber(totalBuybackAmountProvided).toNumber(),
    applyFeeAmount: new BigNumber(applyFeeAmount).toNumber(),
    currentRound: new BigNumber(currentRound).toNumber(),
    roundStartTimestamp: new BigNumber(roundStartTimestamp).toNumber(),
    roundPeriod: new BigNumber(roundPeriod).toNumber(),
    lockupPeriod: new BigNumber(lockupPeriod).toNumber(),
  }
}

export default fetchPool
