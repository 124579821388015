import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import tokens from 'config/constants/tokens'
import { getAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

const fetchBnbCakePrice = async () => {
  const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
  const busdAddress = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
  const calls = [
    // Balance of token in the BNB-BUSD LP contract
    {
      address: bnbAddress,
      name: 'balanceOf',
      params: ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'],
    },
    // Balance of quote token on BNB-BUSD LP contract
    {
      address: busdAddress,
      name: 'balanceOf',
      params: ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'],
    },
    // Balance of token in the CAKE-BUSD LP contract
    {
      address: getAddress(tokens.cake.address),
      name: 'balanceOf',
      params: ['0x804678fa97d91B974ec2af3c843270886528a9E6'],
    },
    // Balance of quote token on CAKE-BUSD LP contract
    {
      address: busdAddress,
      name: 'balanceOf',
      params: ['0x804678fa97d91B974ec2af3c843270886528a9E6'],
    },
  ]
  const multiResult = await multicall(erc20, calls)
  const [
    tokenBalanceBnbBusdLP,
    quoteTokenBalanceBnbBusdLP,
    tokenBalanceCakeBusdLP,
    quoteTokenBalanceCakeBusdLP,
  ] = multiResult
  const bnbPrice = new BigNumber(quoteTokenBalanceBnbBusdLP).div(new BigNumber(tokenBalanceBnbBusdLP))
  const cakePrice = new BigNumber(quoteTokenBalanceCakeBusdLP).div(new BigNumber(tokenBalanceCakeBusdLP))
  return [bnbPrice.toFixed(), cakePrice.toFixed()]
}

export default fetchBnbCakePrice
