/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PriceApiResponse, PriceApiThunk, PriceState, SummaryApiResponse } from 'state/types'
import fetchBnbCakePrice from './fetchBnbCakePrice'

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  bnbPrice: '0',
  cakePrice: '0',
}

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setBnbCakePriceData: (state, action) => {
      state.bnbPrice = action.payload[0]
      state.cakePrice = action.payload[1]
    },
    setCAKEPriceData: (state, action) => {
      state.cakePrice = action.payload
    },
  },
})

// Actions
export const { setBnbCakePriceData } = pricesSlice.actions

// Thunks
export const fetchBnbCakePrices = () => async (dispatch) => {
  const [bnbPrice, cakePrice] = await fetchBnbCakePrice()

  dispatch(setBnbCakePriceData([bnbPrice, cakePrice]))
}

export default pricesSlice.reducer
