import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@pancakeswap-libs/uikit'

const Outer = styled(Box)<{ background?: string }>`
  // background: ${({ theme, background }) => background || theme.colors.gradients.bubblegum};
  background-image: url('/images/honeycomb/header.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 997px 190px;
  height: 190px;
  width: 997px;
  border-radius: 30px;
  padding-top: 20px;
  & > h1:first-child {
    font-family: 'Caramel Sweets', sans-serif;
    font-weight: 400;
  }
  & > h1 {
    font-family: 'Baloo Bhai 2', cursive;
    font-weight: 400;
  }
`

const PageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Flex justifyContent="center" width="100%">
    <Outer background={background} {...props}>
      {children}
    </Outer>
  </Flex>
)

export default PageHeader
