import React, { useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Card, LinkExternal, Link, Button } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import useI18n from 'hooks/useI18n'
import usePersistState from 'hooks/usePersistState'
import { usePools, useBlock } from 'state/hooks'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import PageHeader from 'components/PageHeader'
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'

const PoolPage = styled(Page)`
  margin: 0px;
  max-width: 100vw;
  padding: 20px 0px 200px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 20px 0px 500px 0px;
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const StyledFarmStakingCard = styled(Card)`
  max-width: 352px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: unset;

  & > img {
    margin-top: 3px;
    width: 1000px;
  }
`

const Pools: React.FC = () => {
  const { path } = useRouteMatch()
  const TranslateString = useI18n()
  const { account } = useWeb3React()
  const pools = usePools(account).filter((pool) => !pool.nftImageUrl)
  //  const { currentBlock } = useBlock()
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'pancake_pool_staked')
  const [finishedPools, openPools] = useMemo(
    () => partition(pools, (pool) => pool.isFinished), // || currentBlock > Number(pool.endBlock) || Number(pool.endBlock) === 0),
    [pools],
  )
  // Todo revert this after apply test pool

  const stakedOnlyPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools],
  )
  const hasStakeInFinishedPools = useMemo(
    () => finishedPools.some((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [finishedPools],
  )
  // This pool is passed explicitly to the cake vault
  // const cakePoolData = useMemo(() => openPools.find((pool) => pool.sousId === 0), [openPools])

  return (
    <>
      <PageHeader>
        <Heading as="h1" size="lg" color="textMenu" mt="15px" mb="15px" textAlign="center">
          ROYAL JELLY
        </Heading>
        <br />
        {/* extra */}
        <Wrapper>
          <Link external href="https://honeyfarm.gitbook.io/honeycomb/products/royal-jelly">
            <Button style={{ borderRadius: 100 }}>Learn More</Button>
          </Link>
          {/*
          <Button size="sm" mt="40px">
            <a href={learnMoreUrl}>Learn More</a>
          </Button>
          */}
        </Wrapper>
      </PageHeader>
      <PoolPage>
        <PoolTabButtons
          stakedOnly={stakedOnly}
          setStakedOnly={setStakedOnly}
          hasStakeInFinishedPools={hasStakeInFinishedPools}
          isNft={false}
        />
        <FlexLayout>
          <Route exact path={`${path}`}>
            <>
              {stakedOnly
                ? orderBy(stakedOnlyPools, ['sortOrder']).map((pool) => (
                    <PoolCard key={pool.sousId} pool={pool} account={account} path={path} />
                  ))
                : orderBy(openPools, ['sortOrder']).map((pool) => (
                    <PoolCard key={pool.sousId} pool={pool} account={account} path={path} />
                  ))}
            </>
          </Route>
          <Route path={`${path}/history`}>
            {orderBy(finishedPools, ['sortOrder']).map((pool) => (
              <PoolCard key={pool.sousId} pool={pool} account={account} path={path} />
            ))}
          </Route>
        </FlexLayout>
        <StyledFarmStakingCard>
          <Link href="https://forms.gle/bKRAmUQh7BWQJT2w7" target="_blank">
            <img alt="" src="/images/banner/jelly_partnership.png" width="1000px" height="300px" />
          </Link>
        </StyledFarmStakingCard>
      </PoolPage>
    </>
  )
}

export default Pools
