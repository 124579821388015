import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchBuybackUserDataAsync } from 'state/actions'
import { applyBuyback } from 'utils/callHelpers'
import { useBuyback } from './useContract'

const useBuybackApply = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const buybackContract = useBuyback()

  const handleApplyBuyback = useCallback(
    async (amount: string) => {
      const txHash = await applyBuyback(buybackContract, amount, account)
      dispatch(fetchBuybackUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, buybackContract],
  )

  return { onApplyBuyback: handleApplyBuyback }
}

export default useBuybackApply
