/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import fetchBuybackPool from './fetchBuybackPool'
import fetchBuybackUserAccount from './fetchBuybackUser'
import { BuybackState } from '../types'

const initialState: BuybackState = {
  data: {
    pool: {
      buybackPrice: 0,
      buybackAmount: 0,
      totalApplyAmountThisRound: 0,
      totalBuybackAmountProvided: 0,
      applyFeeAmount: 0,
      currentRound: 0,
      roundStartTimestamp: 0,
      roundPeriod: 0,
      lockupPeriod: 0,
      totalRewarded: 0,
    },
    user: {
      allowance: 0,
      appliedAmount: 0,
      buybackedAmount: 0,
      lastAppliedTimestamp: 0,
      tokenBalance: '0',
    },
    //    pool: {
    //      buybackPrice: new BigNumber(0),
    //      epochAmount: new BigNumber(0),
    //      totalAmountToProvide: new BigNumber(0),
    //      totalAmountProvided: new BigNumber(0),
    //      currentEpoch: new BigNumber(0),
    //      timestampNextEpoch: new BigNumber(0),
    //      totalApplyAmountThisEpoch: new BigNumber(0),
    //      applyFeeAmount: new BigNumber(0),
    //    },
    //    user: {
    //      allowance: new BigNumber(0),
    //      tokenBalance: new BigNumber(0),
    //      waitingAmount: new BigNumber(0),
    //      sellTokenBalance: new BigNumber(0),
    //      buybackTokenBalance: new BigNumber(0),
    //    },
  },
}

export const buybackSlice = createSlice({
  name: 'Buyback',
  initialState,
  reducers: {
    setBuybackPoolData: (state, action) => {
      const buybackPoolData = action.payload
      state.data.pool = buybackPoolData
    },
    setBuybackUserData: (state, action) => {
      const buybackUserData = action.payload
      state.data.user = buybackUserData
    },
  },
})

// Actions
export const { setBuybackPoolData, setBuybackUserData } = buybackSlice.actions

// Thunks
export const fetchBuybackPoolDataAsync = () => async (dispatch) => {
  const pool = await fetchBuybackPool()
  dispatch(setBuybackPoolData(pool))
}

export const fetchBuybackUserDataAsync = (account: string) => async (dispatch) => {
  const buybackContractInfo = await fetchBuybackUserAccount(account)

  const data = {
    allowance: buybackContractInfo.allowance,
    tokenBalance: buybackContractInfo.tokenBalance,
    appliedAmount: buybackContractInfo.appliedAmount,
    buybackedAmount: buybackContractInfo.buybackedAmount,
    lastAppliedTimestamp: buybackContractInfo.lastAppliedTimestamp,
  }
  dispatch(setBuybackUserData(data))
}

export default buybackSlice.reducer
