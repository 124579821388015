import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import pcsV2MasterchefAbi from 'config/abi/PCS-v2-masterchef.json'
import strategyAbi from 'config/abi/strategy.json'
import multicall from 'utils/multicall'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import { getAddress, getMasterChefAddress, getPCSv2MasterChefAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

interface farmFetchConfigI {
  farm: FarmConfig
  tokenBalanceLP?: any
  quoteTokenBalanceLP?: any
  lpTotalSupply?: any
  tokenDecimals?: any
  quoteTokenDecimals?: any
  info?: any
  totalAllocPoint?: any
  earningPerBlock?: any
  pid?: any
  performanceFee?: any
  pancakePoolInfo?: any
  pancakeLpAddress?: any
  pancakeTotalAllocPoint?: any
  cakePerBlock?: any
  pancakeQuoteTokenBalanceLP?: any
  pancakeLpTokenBalanceMC?: any
  pancakeLpTotalSupply?: any
}
interface multicallI {
  address: string
  name: string
  params?: Array<number | string>
}

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  let farmFetchs = farmsToFetch.map(
    (farms): farmFetchConfigI => {
      return { farm: farms }
    },
  )
  const tokenInfoMC2DArray: multicallI[][] = farmFetchs.map((farmfetch) => {
    const farmConfig = farmfetch.farm
    const lpAddress = getAddress(farmConfig.lpAddresses)
    return [
      // Balance of token in the LP contract
      {
        address: getAddress(farmConfig.token.address),
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of quote token on LP contract
      {
        address: getAddress(farmConfig.quoteToken.address),
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: getAddress(farmConfig.token.address),
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: getAddress(farmConfig.quoteToken.address),
        name: 'decimals',
      },
    ]
  })
  const masterchefMC2DArray: multicallI[][] = farmFetchs.map((farmfetch) => [
    {
      address: getMasterChefAddress(),
      name: 'poolInfo',
      params: [farmfetch.farm.pid],
    },
    {
      address: getMasterChefAddress(),
      name: 'totalAllocPoint',
    },
    {
      address: getMasterChefAddress(),
      name: 'earningsPerSecond',
    },
  ])
  const tokenInfoMC: multicallI[] = [].concat(...tokenInfoMC2DArray)
  const masterchefMC: multicallI[] = [].concat(...masterchefMC2DArray)
  const [tokenInfoMCResult, masterchefMCResult] = await Promise.all([
    multicall(erc20, tokenInfoMC),
    multicall(masterchefABI, masterchefMC),
  ]).catch((error) => {
    console.log(tokenInfoMC)
    console.log(masterchefMC)
    throw new Error(`multicall nontoken: ${error}`)
  })

  farmFetchs = farmFetchs.map<farmFetchConfigI>((farm, i) => {
    return {
      ...farm,
      tokenBalanceLP: tokenInfoMCResult[i * 5],
      quoteTokenBalanceLP: tokenInfoMCResult[i * 5 + 1],
      lpTotalSupply: tokenInfoMCResult[i * 5 + 2],
      tokenDecimals: tokenInfoMCResult[i * 5 + 3],
      quoteTokenDecimals: tokenInfoMCResult[i * 5 + 4],
      info: masterchefMCResult[i * 3],
      totalAllocPoint: masterchefMCResult[i * 3 + 1],
      earningPerBlock: masterchefMCResult[i * 3 + 2],
    }
  })
  let pancakeFarms = farmFetchs.filter((farmFetch) => farmFetch.farm.isPancake)
  const strategyMC2DArray: multicallI[][] = pancakeFarms.map((farmfetch) => [
    {
      address: farmfetch.info.strategy,
      name: 'pid',
    },

    {
      address: farmfetch.info.strategy,
      name: 'performanceFeeBips',
    },
  ])

  const strategyMC: multicallI[] = [].concat(...strategyMC2DArray)

  const strategyMCResult = await multicall(strategyAbi, strategyMC).catch((error) => {
    console.log(strategyMC)
    throw new Error(`multicall nontoken: ${error}`)
  })

  pancakeFarms = pancakeFarms.map<farmFetchConfigI>((farm, i) => {
    return {
      ...farm,
      pid: strategyMCResult[i * 2],
      performanceFee: strategyMCResult[i * 2 + 1],
    }
  })

  const pancakeMC2DArray: multicallI[][] = pancakeFarms.map((farmfetch) => [
    {
      address: getPCSv2MasterChefAddress(),
      name: 'poolInfo',
      params: farmfetch.pid,
    },

    {
      address: getPCSv2MasterChefAddress(),
      name: 'lpToken',
      params: farmfetch.pid,
    },
    {
      address: getPCSv2MasterChefAddress(),
      name: 'totalRegularAllocPoint',
    },
    {
      address: getPCSv2MasterChefAddress(),
      name: 'cakePerBlock',
      params: [true],
    },
  ])

  const pancakeMC: multicallI[] = [].concat(...pancakeMC2DArray)
  const pancakeMCResult = await multicall(pcsV2MasterchefAbi, pancakeMC).catch((error) => {
    console.log(pancakeMC)
    throw new Error(`multicall nontoken: ${error}`)
  })
  pancakeFarms = pancakeFarms.map<farmFetchConfigI>((farm, i) => {
    return {
      ...farm,
      pancakePoolInfo: pancakeMCResult[i * 4],
      pancakeLpAddress: pancakeMCResult[i * 4 + 1],
      pancakeTotalAllocPoint: pancakeMCResult[i * 4 + 2],
      cakePerBlock: pancakeMCResult[i * 4 + 3],
    }
  })
  const pancakeTokenInfoMC2DArray = pancakeFarms.map((farmfetch) => [
    // Balance of quote token on LP contract
    {
      address: getAddress(farmfetch.farm.quoteToken.address),
      name: 'balanceOf',
      params: farmfetch.pancakeLpAddress,
    },
    // Balance of LP tokens in the master chef contract
    {
      address: getAddress(farmfetch.farm.lpAddresses),
      name: 'balanceOf',
      params: [getPCSv2MasterChefAddress()],
    },
    // Total supply of LP tokens
    {
      address: farmfetch.pancakeLpAddress[0],
      name: 'totalSupply',
    },
  ])

  const pancakeTokenInfoMC: multicallI[] = [].concat(...pancakeTokenInfoMC2DArray)
  const pancakeTokenInfoMCResult = await multicall(erc20, pancakeTokenInfoMC).catch((error) => {
    console.log(pancakeTokenInfoMC)
    throw new Error(`multicall nontoken: ${error}`)
  })
  pancakeFarms = pancakeFarms.map<farmFetchConfigI>((farm, i) => {
    return {
      ...farm,
      pancakeQuoteTokenBalanceLP: pancakeTokenInfoMCResult[i * 3],
      pancakeLpTokenBalanceMC: pancakeTokenInfoMCResult[i * 3 + 1],
      pancakeLpTotalSupply: pancakeTokenInfoMCResult[i * 3 + 2],
    }
  })
  return farmFetchs.map((farm) => {
    let farmFetched = farm
    if (farm.farm.isPancake) {
      farmFetched = pancakeFarms.find((pancakeFarm) => pancakeFarm.farm.pid === farm.farm.pid)
    }

    const lpTokenBalanceMC = new BigNumber(Number(farmFetched.info.totalShares))
      .times(Number(farmFetched.info.lpPerShare))
      .div(DEFAULT_TOKEN_DECIMAL)
    let tokenAmount
    let lpTotalInQuoteToken
    let tokenPriceVsQuote
    let quoteTokenAmount

    if (farmFetched.farm.isTokenOnly || farmFetched.farm.isKingdomToken) {
      tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(farmFetched.tokenDecimals))
      if (farmFetched.farm.token.symbol === 'BUSD' && farmFetched.farm.quoteToken.symbol === 'BUSD') {
        tokenPriceVsQuote = new BigNumber(1)
      } else {
        tokenPriceVsQuote = new BigNumber(farmFetched.quoteTokenBalanceLP).div(
          new BigNumber(farmFetched.tokenBalanceLP),
        )
      }
      lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
    } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(farmFetched.lpTotalSupply))

      // Total value in staking in quote token value
      lpTotalInQuoteToken = new BigNumber(farmFetched.quoteTokenBalanceLP)
        .div(DEFAULT_TOKEN_DECIMAL)
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      tokenPriceVsQuote = new BigNumber(farmFetched.quoteTokenBalanceLP).div(new BigNumber(farmFetched.tokenBalanceLP))
      //        if (farmConfig.token.symbol === 'BEAR' && farmConfig.quoteToken.symbol === 'BUSD') {
      //          console.log('@@quoteTokenBalanceLP', Number(quoteTokenBalanceLP[0]._hex)/10**18)
      //          console.log('@@tokenBalanceLP', Number(tokenBalanceLP[0]._hex)/10**18)
      //        }

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      tokenAmount = new BigNumber(farmFetched.tokenBalanceLP)
        .div(BIG_TEN.pow(farmFetched.tokenDecimals))
        .times(lpTokenRatio)
      quoteTokenAmount = new BigNumber(farmFetched.quoteTokenBalanceLP)
        .div(BIG_TEN.pow(farmFetched.quoteTokenDecimals))
        .times(lpTokenRatio)

      if (tokenAmount.comparedTo(0) > 0) {
        tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
      } else {
        tokenPriceVsQuote = new BigNumber(farmFetched.quoteTokenBalanceLP).div(
          new BigNumber(farmFetched.tokenBalanceLP),
        )
      }
    }

    const tokenAmountTotal = new BigNumber(farmFetched.tokenBalanceLP).div(BIG_TEN.pow(farmFetched.tokenDecimals))

    const allocPoint = new BigNumber(farmFetched.info.allocPoint._hex)
    const poolWeight = allocPoint.div(new BigNumber(farmFetched.totalAllocPoint))
    const lpTokenRatio = new BigNumber(farmFetched.pancakeLpTokenBalanceMC).div(
      new BigNumber(farmFetched.pancakeLpTotalSupply),
    )
    const quoteTokenAmountTotal = new BigNumber(farmFetched.pancakeQuoteTokenBalanceLP).div(
      BIG_TEN.pow(farmFetched.quoteTokenDecimals),
    )

    // Amount of quoteToken in the LP that are staked in the MC
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    const pancakeLpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))
    const pancakeAllocPoint =
      farmFetched.pancakePoolInfo !== undefined ? new BigNumber(farmFetched.pancakePoolInfo.allocPoint._hex) : BIG_ZERO
    const pancakePoolWeight = pancakeAllocPoint.div(new BigNumber(farmFetched.pancakeTotalAllocPoint || BIG_TEN))
    return {
      ...farmFetched.farm,
      tokenAmount: tokenAmount.toJSON(),
      // quoteTokenAmount: quoteTokenAmount.toJSON(),
      lpTotalSupply: new BigNumber(farmFetched.lpTotalSupply).toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
      // tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
      poolWeight: poolWeight.toJSON(),
      multiplier: `${allocPoint.div(100).toString()}X`,
      depositFeeBP: farmFetched.info.depositFeeBP,
      performanceFee: new BigNumber(farmFetched.performanceFee).div(10000).toNumber(),
      earningPerBlock: new BigNumber(farmFetched.earningPerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
      tokenAmountTotal: tokenAmountTotal.toJSON(),
      isWithdrawFee: farmFetched.info.isWithdrawFee,
      cakePerBlock:
        farmFetched.cakePerBlock === null
          ? null
          : new BigNumber(farmFetched.cakePerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
      pancakePoolWeight: pancakePoolWeight === null ? null : pancakePoolWeight.toJSON(),
      pancakeLpTotalInQuoteToken: pancakeLpTotalInQuoteToken === null ? null : pancakeLpTotalInQuoteToken.toJSON(),
      pancakeLpAddress: farmFetched.pancakeLpAddress ? farmFetched.pancakeLpAddress[0] : undefined,
    }
  })
}

/* const beforefetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses)

      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        //        {
        //          address: farmConfig.isTokenOnly ? tokenAddress : lpAddress,
        //          name: 'balanceOf',
        //          params: [getMasterChefAddress()],
        //        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
      ]
      const multiResult = await multicall(erc20, calls)
      const [tokenBalanceLP, quoteTokenBalanceLP, lpTotalSupply, tokenDecimals, quoteTokenDecimals] = multiResult

      const mCalls = [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'earningsPerSecond',
        },
      ]

      const [info, totalAllocPoint, earningPerBlock] = await multicall(masterchefABI, mCalls).catch((error) => {
        console.log(mCalls)
        throw new Error(`multicall nontoken: ${error}`)
      })

      const { strategy, lpPerShare, totalShares } = info

      const lpTokenBalanceMC = new BigNumber(Number(totalShares)).times(Number(lpPerShare)).div(DEFAULT_TOKEN_DECIMAL)
      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      let quoteTokenAmount

      if (farmConfig.isTokenOnly || farmConfig.isKingdomToken) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
        if (farmConfig.token.symbol === 'BUSD' && farmConfig.quoteToken.symbol === 'BUSD') {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
          .div(DEFAULT_TOKEN_DECIMAL)
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
        //        if (farmConfig.token.symbol === 'BEAR' && farmConfig.quoteToken.symbol === 'BUSD') {
        //          console.log('@@quoteTokenBalanceLP', Number(quoteTokenBalanceLP[0]._hex)/10**18)
        //          console.log('@@tokenBalanceLP', Number(tokenBalanceLP[0]._hex)/10**18)
        //        }

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)).times(lpTokenRatio)
        quoteTokenAmount = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)).times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
        }
      }

      const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      let performanceFee = null
      let pancakePoolWeight = null
      let cakePerBlock = null
      let pancakeLpTotalInQuoteToken = null
      if (farmConfig.isPancake === true) {
        const sCalls = [
          {
            address: strategy,
            name: 'pid',
          },

          {
            address: strategy,
            name: 'performanceFeeBips',
          },
        ]
        const [pid, pperformanceFee] = await multicall(strategyAbi, sCalls).catch((error) => {
          console.log(sCalls)
          console.log(`farm pid : ${farmConfig.pid}`)
          throw new Error(`multicall nontoken: ${error}`)
        })
        performanceFee = pperformanceFee
        const pCalls = [
          {
            address: getPCSv2MasterChefAddress(),
            name: 'poolInfo',
            params: pid,
          },

          {
            address: getPCSv2MasterChefAddress(),
            name: 'lpToken',
            params: pid,
          },
          {
            address: getPCSv2MasterChefAddress(),
            name: 'totalRegularAllocPoint',
          },
          {
            address: getPCSv2MasterChefAddress(),
            name: 'cakePerBlock',
            params: [true],
          },
        ]

        const [pancakePoolInfo, pancakeLpAddress, pancakeTotalAllocPoint, cakePerBlock_] = await multicall(
          pcsV2MasterchefAbi,
          pCalls,
        ).catch((error) => {
          console.log(pCalls)
          throw new Error(`multicall nontoken: ${error}`)
        })
        const pancakeCalls = [
          // Balance of quote token on LP contract
          {
            address: getAddress(farmConfig.quoteToken.address),
            name: 'balanceOf',
            params: pancakeLpAddress,
          },
          // Balance of LP tokens in the master chef contract
          {
            address: lpAddress,
            name: 'balanceOf',
            params: [getPCSv2MasterChefAddress()],
          },
          // Total supply of LP tokens
          {
            address: pancakeLpAddress[0],
            name: 'totalSupply',
          },
        ]
        const multiPancakeResult = await multicall(erc20, pancakeCalls)
        const [pancakeQuoteTokenBalanceLP, pancakeLpTokenBalanceMC, pancakeLpTotalSupply] = multiPancakeResult

        const lpTokenRatio = new BigNumber(pancakeLpTokenBalanceMC).div(new BigNumber(pancakeLpTotalSupply))
        const quoteTokenAmountTotal = new BigNumber(pancakeQuoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

        // Amount of quoteToken in the LP that are staked in the MC
        const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

        // Total staked in LP, in quote token value
        pancakeLpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))
        cakePerBlock = cakePerBlock_
        const pancakeAllocPoint = new BigNumber(pancakePoolInfo.allocPoint._hex)
        pancakePoolWeight = pancakeAllocPoint.div(new BigNumber(pancakeTotalAllocPoint))
      }
      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        // tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        performanceFee: new BigNumber(performanceFee).div(10000).toNumber(),
        earningPerBlock: new BigNumber(earningPerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        isWithdrawFee: info.isWithdrawFee,
        cakePerBlock: cakePerBlock === null ? null : new BigNumber(cakePerBlock).div(DEFAULT_TOKEN_DECIMAL).toNumber(),
        pancakePoolWeight: pancakePoolWeight === null ? null : pancakePoolWeight.toJSON(),
        pancakeLpTotalInQuoteToken: pancakeLpTotalInQuoteToken === null ? null : pancakeLpTotalInQuoteToken.toJSON(),
      }
    }),
  )
  return data
}
*/

export default fetchFarms
