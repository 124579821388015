export default {
  cake: {
    56: '0x0EdB3301437B255310cCdCb5Be9f736764947696',
    666: 'This is BEE token',
    97: '',
  },
  masterChef: {
    56: '0xbCfA2a18719be3D6eB0E9e78fE8F40130575995F',
    97: '',
  },
  referral: {
    56: '0xE9F2bf90ae0218d10Db50c2072Ac388e11C038ae',
    97: '',
  },
  pancakeLP: {
    56: '0xf57DCF1B480CAFedd708ecf549A71B568A557692',
    97: '',
  },
  buyback: {
    56: '0x973f81C5c3ECBFC0563A80786807f602a9902C2c',
    97: '0xAEA2ecFBB59d0815B9F8e9111DFf640f7F49BC50',
  },
  kingdoms: {
    56: '0x2e72f4b196b9e5b89c29579cc135756a00e6cbbd',
    97: '',
  },
  pcsV2masterChef: {
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    97: '',
  },
  bakery: {
    56: '0x20ec291bb8459b6145317e7126532ce7ece5056f',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  multiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  cake_real: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '',
  },

  sousChef: {
    97: '',
    56: '',
  },
  pancakeProfile: {
    56: '',
    97: '',
  },
  pancakeRabbits: {
    56: '',
    97: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
  },
  easterNft: {
    56: '',
    97: '',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '',
    97: '',
  },
}
