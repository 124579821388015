import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchBuybackUserDataAsync } from 'state/actions'
import { withdrawApplyToken } from 'utils/callHelpers'
import { useBuyback } from './useContract'

const useBuybackWithdrawApplyToken = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const buybackContract = useBuyback()

  const handleBuybackWithdrawApplyToken = useCallback(async () => {
    const txHash = await withdrawApplyToken(buybackContract, account)
    dispatch(fetchBuybackUserDataAsync(account))
    console.info(txHash)
  }, [account, dispatch, buybackContract])

  return { onBuybackWithdrawApplyToken: handleBuybackWithdrawApplyToken }
}

export default useBuybackWithdrawApplyToken
