import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { Card, CardBody, Text, Flex, Heading, Button, useModal, Skeleton } from '@pancakeswap-libs/uikit'
import UnlockButton from 'components/UnlockButton'
import CountDown, { CountdownApi } from 'react-countdown'
import { useBuybackPool, useBuybackUser } from 'state/hooks'
import { MAIN_TOKEN_SYMBOL } from 'config'
import { getFullDisplayBalance } from 'utils/formatBalance'
import useApprove from 'hooks/useBuybackApprove'
import { getCakeAddress } from 'utils/addressHelpers'
import useBuybackWithdrawApplyToken from 'hooks/useBuybackWithdrawApplyToken'
import useBuybackWithdrawBuybackToken from 'hooks/useBuybackWithdrawBuybackToken'
import { useERC20 } from 'hooks/useContract'
import useBuybackApply from 'hooks/useBuybackApply'
import useBuybackReapply from 'hooks/useBuybackReapply'
import ApplyModal from '../ApplyModal'
import WithdrawModal from '../WithdrawModal'

interface BuybackCardProps {
  account?: string
}
const StyledHKPCard = styled(Card)`
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 437px;
  width: 100%;
  min-width: 340px;
`
const HKPCard: React.FC<BuybackCardProps> = ({ account }) => {
  const buybackPoolInfo = useBuybackPool()
  const buybackUserInfo = useBuybackUser()
  const [unlockTimestamp, setUnlockTimestamp] = useState(
    (buybackUserInfo.lastAppliedTimestamp + buybackPoolInfo.lockupPeriod) * 1000,
  )

  const [roundEnd, setRoundEnd] = useState(
    new Date((buybackPoolInfo.roundStartTimestamp + buybackPoolInfo.roundPeriod) * 1000),
  )

  const countdownRound = useRef<CountDown>()
  const countdownLock = useRef<CountDown>()
  useEffect(() => {
    setRoundEnd(new Date((buybackPoolInfo.roundStartTimestamp + buybackPoolInfo.roundPeriod) * 1000))
  }, [buybackPoolInfo.roundStartTimestamp, buybackPoolInfo.roundPeriod])
  useEffect(() => {
    setUnlockTimestamp((buybackUserInfo.lastAppliedTimestamp + buybackPoolInfo.lockupPeriod) * 1000)
  }, [buybackUserInfo.lastAppliedTimestamp, buybackPoolInfo.lockupPeriod])
  useEffect(() => {
    countdownRound.current.getApi().start()
    countdownLock.current.getApi().start()
  }, [unlockTimestamp, roundEnd])
  const { onApplyBuyback } = useBuybackApply()
  const { onBuybackWithdrawApplyToken } = useBuybackWithdrawApplyToken()
  const { onBuybackWithdrawBuybackToken } = useBuybackWithdrawBuybackToken()
  const { onApprove } = useApprove(useERC20(getCakeAddress()))
  const [requestedApproval, setRequestedApproval] = useState(false)
  const isApproved = account && buybackUserInfo.allowance && new BigNumber(buybackUserInfo.allowance).isGreaterThan(0)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApprove])
  const renderApprovalButtonOrBuybackApplyView = () => {
    if (!account) return <UnlockButton mt="8px" width="100%" />
    if (!isApproved)
      return (
        <Button mt="8px" disabled={requestedApproval} onClick={handleApprove} width="100%">
          Approve Contract
        </Button>
      )
    if (buybackUserInfo.appliedAmount > 0)
      return (
        <>
          <Button
            mt="8px"
            disabled={requestedApproval || unlockTimestamp > Date.now()}
            onClick={onPresentWithdrawApplyToken}
            width="49%"
          >
            Withdraw
          </Button>
          <Button
            mt="8px"
            disabled={
              requestedApproval ||
              new BigNumber(buybackPoolInfo.roundStartTimestamp).isZero() ||
              (buybackPoolInfo.roundStartTimestamp + buybackPoolInfo.roundPeriod) * 1000 < Date.now() ||
              buybackPoolInfo.roundStartTimestamp * 1000 > Date.now()
            }
            onClick={onPresentApply}
            width="49%"
          >
            Apply
          </Button>
        </>
      )

    return (
      <Button
        mt="8px"
        disabled={
          requestedApproval ||
          new BigNumber(buybackPoolInfo.roundStartTimestamp).isZero() ||
          (buybackPoolInfo.roundStartTimestamp + buybackPoolInfo.roundPeriod) * 1000 < Date.now() ||
          buybackPoolInfo.roundStartTimestamp * 1000 > Date.now()
        }
        onClick={onPresentApply}
        width="100%"
      >
        Apply
      </Button>
    )
  }
  const [onPresentApply] = useModal(
    <ApplyModal
      max={new BigNumber(buybackUserInfo.tokenBalance)}
      onConfirm={onApplyBuyback}
      applyFeeAmount={new BigNumber(buybackPoolInfo.applyFeeAmount)}
      lockupPeriod={buybackPoolInfo.lockupPeriod}
      tokenName={MAIN_TOKEN_SYMBOL}
    />,
  )
  const [onPresentWithdrawApplyToken] = useModal(
    <WithdrawModal onConfirm={onBuybackWithdrawApplyToken} tokenName={MAIN_TOKEN_SYMBOL} />,
  )
  return (
    <StyledHKPCard>
      <Heading size="xl" fontSize="28px" ml={20} mt={20}>
        Round #{buybackPoolInfo.currentRound}
      </Heading>
      <CardBody>
        <Heading size="md">TIMER</Heading>
        <Flex justifyContent="center" padding={20} borderRadius={20} background="rgba(255,255,255,0.1)" m={10}>
          <Text fontSize="40px">
            <CountDown date={roundEnd} intervalDelay={999} daysInHours autoStart={false} ref={countdownRound} />
          </Text>
        </Flex>

        <Flex justifyContent="space-between" color="yellow">
          <Text style={{ fontSize: '16px' }} color="textSubtle">
            PRICE
          </Text>
          <Text bold style={{ fontSize: '16px' }} color="textSubtle">
            {buybackPoolInfo.buybackPrice} BUSD
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text style={{ fontSize: '16px' }} color="textSubtle">
            BUYBACK AMOUNT
          </Text>
          <Text bold style={{ fontSize: '16px' }} color="textSubtle">
            {getFullDisplayBalance(new BigNumber(buybackPoolInfo.buybackAmount), 18, 0)} BUSD
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb={40}>
          <Text style={{ fontSize: '16px' }} color="textSubtle">
            TOTAL APPLIED {MAIN_TOKEN_SYMBOL}
          </Text>
          <Text bold style={{ fontSize: '16px' }} color="textSubtle">
            {getFullDisplayBalance(new BigNumber(buybackPoolInfo.totalApplyAmountThisRound), 18, 0)} {MAIN_TOKEN_SYMBOL}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Text style={{ fontSize: '16px' }}>Your Balance</Text>
          <Text bold style={{ fontSize: '16px' }}>
            {getFullDisplayBalance(new BigNumber(buybackUserInfo.tokenBalance), 18, 4)} {MAIN_TOKEN_SYMBOL}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Text style={{ fontSize: '16px' }}>
            {new BigNumber(buybackPoolInfo.roundStartTimestamp).isZero() ||
            new BigNumber(buybackPoolInfo.roundStartTimestamp).isGreaterThan(buybackUserInfo.lastAppliedTimestamp)
              ? 'Your Locked up Balance'
              : 'Your Applied Balance'}
          </Text>
          <Text bold style={{ fontSize: '16px' }}>
            {getFullDisplayBalance(new BigNumber(buybackUserInfo.appliedAmount), 18, 4)} {MAIN_TOKEN_SYMBOL}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Text style={{ fontSize: '16px' }}>Your Lock Up Release</Text>
          <CountDown
            date={new Date(unlockTimestamp)}
            renderer={renderer}
            daysInHours
            autoStart={false}
            ref={countdownLock}
          />
        </Flex>
        <Flex justifyContent="space-between">{renderApprovalButtonOrBuybackApplyView()}</Flex>
        <Flex
          justifyContent="center"
          padding={20}
          borderRadius={20}
          background="rgba(255,255,255,0.1)"
          flexDirection="column"
          mt={3}
        >
          <Heading size="md" style={{ fontSize: '15px' }}>
            Your Earned Prize
          </Heading>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="16px" textAlign="center">
              {getFullDisplayBalance(new BigNumber(buybackUserInfo.buybackedAmount), 18, 2)} BUSD
            </Text>
            <Button
              ml={10}
              disabled={new BigNumber(buybackUserInfo.buybackedAmount).isLessThanOrEqualTo(0)}
              onClick={() => {
                onBuybackWithdrawBuybackToken()
              }}
            >
              Withdraw
            </Button>
          </Flex>
        </Flex>
      </CardBody>
    </StyledHKPCard>
  )
}

export default HKPCard

const renderer = ({ days, hours, minutes, seconds }) => {
  // Render a countdown
  return Number.isNaN(seconds) ? <Skeleton width={60} /> : `${hours + days * 24}h ${minutes}min ${seconds}s Left`
}
