import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import MulticallABI from 'config/abi/Multicall.json'
import multicall from 'utils/multicall'
import { LPConfig } from 'config/constants/types'
import { getAddress, getMulticallAddress, getPancakeLPAddress } from 'utils/addressHelpers'
import { BIG_TEN } from 'utils/bigNumber'

export const fetchUserLPsBalance = async (account: string, LPToFetch: LPConfig[]) => {
  const pancakeLPAddress = getPancakeLPAddress()
  const data = await Promise.all(
    LPToFetch.map(async (lp) => {
      const lpContractAddress = getAddress(lp.lpAddresses)
      const tokenAddress = getAddress(lp.token.address)
      const BNBContractAddress = getMulticallAddress()
      const isBNB = lp.quoteToken.symbol === 'BNB'

      const QuoteTokenAddress = getAddress(lp.quoteToken.address)
      const calls = [
        // Balance of LP on LP contract
        {
          address: lpContractAddress,
          name: 'balanceOf',
          params: [account],
        },
        // Balance of token on LP contract
        {
          address: tokenAddress,
          name: 'balanceOf',
          params: [lpContractAddress],
        },
        // Balance of quote token on LP contract
        {
          address: QuoteTokenAddress,
          name: 'balanceOf',
          params: [lpContractAddress],
        },
        // Balance of token on wallet
        {
          address: tokenAddress,
          name: 'balanceOf',
          params: [account],
        },
        // Balance of quote token on wallet
        {
          address: QuoteTokenAddress,
          name: 'balanceOf',
          params: [account],
        },
        // Total Supply of LP
        {
          address: lpContractAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: tokenAddress,
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: QuoteTokenAddress,
          name: 'decimals',
        },
        // get allowance token
        {
          address: tokenAddress,
          name: 'allowance',
          params: [account, pancakeLPAddress],
        },
        // get allowance quote token
        {
          address: QuoteTokenAddress,
          name: 'allowance',
          params: [account, pancakeLPAddress],
        },
        // get allowance LP
        {
          address: lpContractAddress,
          name: 'allowance',
          params: [account, pancakeLPAddress],
        },
      ]
      const rawLPMuilticall: any[] = await multicall(erc20ABI, calls).catch((error) => {
        console.log(calls)
        throw new Error(`multicall nontoken: ${error}`)
      })
      const [
        accountBalance,
        tokenBalanceLP,
        quoteTokenBalanceLP,
        tokenBalanceWallet,
        quoteTokenBalanceWallet,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        tokenAllowance,
        quoteTokenAllowance,
        lpAllowance,
      ] = rawLPMuilticall
      const accountLPBalance = new BigNumber(accountBalance)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
      const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
      const tokenBalance = new BigNumber(tokenBalanceWallet).div(BIG_TEN.pow(tokenDecimals)).toFixed()
      const quoteTokenBalance = new BigNumber(quoteTokenBalanceWallet).div(BIG_TEN.pow(quoteTokenDecimals)).toFixed()
      const tokenPriceVsQuote = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(tokenBalanceLP))
      const tokenBalanceInLP = tokenAmount.times(accountLPBalance).div(lpTotalSupply).toFixed()
      const quoteTokenBalanceInLP = quoteTokenAmount.times(accountLPBalance).div(lpTotalSupply).toFixed()
      const lpBalance =
        lp.decimals !== undefined
          ? accountLPBalance.div(BIG_TEN.pow(lp.decimals)).toJSON()
          : accountLPBalance.div(BIG_TEN.pow(new BigNumber(18))).toJSON()
      // for BNB balance in wallet
      const bnbCalls = [
        {
          address: BNBContractAddress,
          name: 'getEthBalance',
          params: [account],
        },
      ]
      let BNBBalanceInWallet: any = 0
      if (isBNB) {
        BNBBalanceInWallet = new BigNumber((await multicall(MulticallABI, bnbCalls))[0])
          .div(BIG_TEN.pow(quoteTokenDecimals))
          .toJSON()
      }
      return {
        ...lp,
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        userData: {
          lpBalance,
          tokenBalance,
          quoteTokenBalance: isBNB ? BNBBalanceInWallet : quoteTokenBalance,
          tokenBalanceInLP,
          quoteTokenBalanceInLP,
          tokenAllowance: new BigNumber(tokenAllowance).toFixed(),
          quoteTokenAllowance: isBNB ? '99999999999' : new BigNumber(quoteTokenAllowance).toFixed(),
          lpAllowance: new BigNumber(lpAllowance).toFixed(),
        },
      }
    }),
  )
  return data
}

export default fetchUserLPsBalance
