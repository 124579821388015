// import tokens from './tokens'
// import farms from './farms'
// import { Ifo, Token } from './types'
import { Ifo } from './types'

/* const cakeBnbLpToken: Token = {
  symbol: farms[1].lpSymbol,
  address: farms[1].lpAddresses,
  decimals: 18,
} */
// 0x03ea7fda77af12fe3c0e34cb0bd31e50c35f2d33
const ifos: Ifo[] = [
  {
    id: 'precomb2tcomb',
    address: '0x4C6d05Ebb30a25BFba7872320394486C5115ee04',
    isActive: true,
    name: 'migration to tCOMB',
    subTitle: 'Get tCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '100',
    raiseAmount: '100',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: '/',
    currency: 'preCOMB',
    currencyAddress: '0x9eAa155EB3a8a8aCFB3ca50aDb1Bf8f55d8F8F6D',
    currencyTokenAddress: '0x9eAa155EB3a8a8aCFB3ca50aDb1Bf8f55d8F8F6D',
    tokenDecimals: 18,
    launchBlockNumber: 1645286400,
    releaseBlockNumber: 1645287000,
  },
]

export default ifos
