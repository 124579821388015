import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, Text, Heading } from '@pancakeswap-libs/uikit'
import ModalActions from 'components/ModalActions'
import TokenInput from 'components/TokenInput'
import useI18n from 'hooks/useI18n'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

interface ApplyModalProps {
  max?: BigNumber
  onConfirm: (amount: string) => void
  applyFeeAmount?: BigNumber
  lockupPeriod?: number
  tokenName: string
  onDismiss?: () => void
}

const ApplyModal: React.FC<ApplyModalProps> = ({
  onConfirm,
  applyFeeAmount,
  max,
  onDismiss,
  tokenName = '',
  lockupPeriod = 48,
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const TranslateString = useI18n()
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(max.div(DEFAULT_TOKEN_DECIMAL).toFixed(18, BigNumber.ROUND_FLOOR))
  }, [max])

  const applyFeeAmountNumber = applyFeeAmount.div(10 ** 18)
  const applyFeeAmountDisplay = applyFeeAmount.div(10 ** 18).toLocaleString()

  return (
    <Modal title={`Apply ${tokenName}`} onDismiss={onDismiss}>
      <Text>
        Apply fee: <b>2%</b>
      </Text>
      <Text>
        Applied {tokenName} will be locked upto <b>{Math.trunc(lockupPeriod / 3600)}hours</b>.
      </Text>
      <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss}>
          {TranslateString(462, 'Cancel')}
        </Button>
        <Button
          disabled={pendingTx || new BigNumber(val).isLessThanOrEqualTo(applyFeeAmountNumber) || val === ''}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        >
          {pendingTx ? TranslateString(488, 'Pending Confirmation') : TranslateString(464, 'Confirm')}
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ApplyModal
