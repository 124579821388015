import contracts from './contracts'
import { VaultConfig } from './types'
import tokens from './tokens'

const vaults: VaultConfig[] = [
  {
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0x0ed7e52944161450477ee417de9cd3a859b14fd0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: tokens.wbnb.symbol,
    quoteTokenAdresses: contracts.wbnb,
    vaultAddresses: {
      97: '',
      56: '0xcB04508f205180f6Bb50E4323380Af1e19bc6b5d',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'CAKE-BUSD',
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: tokens.busd.symbol,
    quoteTokenAdresses: contracts.busd,
    vaultAddresses: {
      97: '',
      56: '0x5C3014d4BA7AA21B27915Cb64343C1A7e85539E7',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: tokens.busd.symbol,
    quoteTokenAdresses: contracts.busd,
    vaultAddresses: {
      97: '',
      56: '0x80CD2F734cA3b7c1771970ac69eE7e5dCEa8eDd2',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'BELT-BNB',
    lpAddresses: {
      97: '',
      56: '0xf3bc6fc080ffcc30d93df48bfa2aa14b869554bb',
    },
    tokenSymbol: 'BELT',
    tokenAddresses: {
      97: '',
      56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
    },
    quoteTokenSymbol: tokens.wbnb.symbol,
    quoteTokenAdresses: contracts.wbnb,
    vaultAddresses: {
      97: '',
      56: '0xA8884354822B5558E5d469489F7A5C7C628337C0',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'TWT-BNB',
    lpAddresses: {
      97: '',
      56: '0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13',
    },
    tokenSymbol: 'TWT',
    tokenAddresses: {
      97: '',
      56: '0x4b0f1812e5df2a09796481ff14017e6005508003',
    },
    quoteTokenSymbol: tokens.wbnb.symbol,
    quoteTokenAdresses: contracts.wbnb,
    vaultAddresses: {
      97: '',
      56: '0x9FFe0B566Ba902D92AdE094eC22587A399f6d115',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'REEF-BNB',
    lpAddresses: {
      97: '',
      56: '0xd63b5CecB1f40d626307B92706Df357709D05827',
    },
    tokenSymbol: 'REEF',
    tokenAddresses: {
      97: '',
      56: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
    },
    quoteTokenSymbol: tokens.wbnb.symbol,
    quoteTokenAdresses: contracts.wbnb,
    vaultAddresses: {
      97: '',
      56: '0x9DC2bB1C67ADA325D4181d50fBf87a31e5605E31',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'TKO-BNB',
    lpAddresses: {
      97: '',
      56: '0xFFd4B200d3C77A0B691B5562D804b3bd54294e6e',
    },
    tokenSymbol: 'TKO',
    tokenAddresses: {
      97: '',
      56: '0x9f589e3eabe42ebc94a44727b3f3531c0c877809',
    },
    quoteTokenSymbol: tokens.wbnb.symbol,
    quoteTokenAdresses: contracts.wbnb,
    vaultAddresses: {
      97: '',
      56: '0x35e28e5F48C8FeAEa1C5B20EB9572a97D85F9fD7',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
  {
    lpSymbol: 'ALPACA-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7752e1fa9f3a2e860856458517008558deb989e3',
    },
    tokenSymbol: 'ALPACA',
    tokenAddresses: {
      97: '',
      56: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    },
    quoteTokenSymbol: tokens.busd.symbol,
    quoteTokenAdresses: contracts.busd,
    vaultAddresses: {
      97: '',
      56: '0x08f2DdEdd993eC8625a0E738d4c797F07c0C0494',
    },
    earnTokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    earnLpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    earnQuoteTokenSymbol: tokens.wbnb.symbol,
    earnQuoteTokenAdresses: contracts.wbnb,
  },
]

export default vaults
