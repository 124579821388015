const tokens = {
  comb: {
    symbol: 'COMB',
    address: {
      56: '0x0EdB3301437B255310cCdCb5Be9f736764947696',
      97: '',
    },
    decimals: 18,
  },
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  tcomb: {
    symbol: 'tCOMB',
    address: {
      56: '0xc6d03Fc3108D15d314B17CcACba010cD8cdF472E',
      97: '',
    },
    decimals: 18,
  },
  preComb: {
    symbol: 'preCOMB',
    address: {
      56: '0x9eAa155EB3a8a8aCFB3ca50aDb1Bf8f55d8F8F6D',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  bee: {
    symbol: 'BEE',
    address: {
      56: '0x1739699DC1205cF923943DA1d983d07026c8d59a',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bee.honeyfarm.finance/',
  },
  tbee: {
    symbol: 'tBEE',
    address: {
      56: '0x8ecedcde6e3264350ca0c19e42495acdc043e758',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bee.honeyfarm.finance/',
  },

  moon: {
    symbol: 'MOON',
    address: {
      56: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://moon.honeyfarm.finance/',
  },
  bear: {
    symbol: 'BEAR',
    address: {
      56: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bear.honeyfarm.finance/',
  },
  honey: {
    symbol: 'HONEY',
    address: {
      56: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://honey.honeyfarm.finance/',
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'BNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  tusd: {
    symbol: 'TUSD',
    address: {
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      97: '',
    },
    decimals: 18,
    projectLink: 'http://www.makerdao.com/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },

  // LP
  bnb_busd: {
    symbol: 'BNB-BUSD',
    isLP: true,
    address: {
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  bear_bnb: {
    symbol: 'BEAR-BNB',
    isLP: true,
    address: {
      56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  moon_bnb: {
    symbol: 'MOON-BNB',
    isLP: true,
    address: {
      56: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  moon_busd: {
    symbol: 'MOON-BUSD',
    isLP: true,
    address: {
      56: '0x1536d05bebb05173a19c44aa1ec0a009544aafc4',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  bee_bnb: {
    symbol: 'BEE-BNB',
    isLP: true,
    address: {
      56: '0x5c80860f8e48d0e32b21d280043dd7b08a1061fb',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  tcomb_busd: {
    symbol: 'tCOMB-BUSD',
    isLP: true,
    address: {
      56: '0x9E0344D2E7D0338AB282fF11D0ED7fd219ce906B',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  comb_busd: {
    symbol: 'COMB-BUSD',
    isLP: true,
    address: {
      56: '0x567B5B5f64d134d7ae21450D9ea5f72AB64566Ea',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  honey_nfh_1: {
    symbol: 'COMB_NFH_#1',
    address: {
      56: '0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949',
      97: '',
    },
    decimals: 18,
    title: 'Bean #9018',
    titleLink: 'https://opensea.io/assets/ethereum/0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949/9018',
    projectLink: 'https://www.azuki.com/beanz',
  },
}

export default tokens
