import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import Container from 'components/layout/Container'
import PageHeader from 'components/PageHeader'
import { Button, Heading, Link } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { fetchBuybackPoolDataAsync, fetchBuybackUserDataAsync } from 'state/actions'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import useRefresh from 'hooks/useRefresh'
import HKPCard from './components/HKPCard'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const HKP = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchBuybackPoolDataAsync())
    if (account) {
      dispatch(fetchBuybackUserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])

  return (
    <Wrapper>
      <PageHeader>
        <Heading as="h1" size="lg" color="textMenu" mt="15px" mb="30px" textAlign="center">
          HONEY KEEPER PROTOCOL
        </Heading>
        {/* extra */}
        <ButtonWrapper>
          <Link external href="https://honeyfarm.gitbook.io/honeycomb/products/hkp">
            <Button style={{ borderRadius: 100 }}>Learn More</Button>
          </Link>
        </ButtonWrapper>
      </PageHeader>
      <Container>
        <HKPCard account={account} />
      </Container>
    </Wrapper>
  )
}

export default HKP
