const config = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0x0EdB3301437B255310cCdCb5Be9f736764947696',
        target: '_blank',
      },
      {
        label: 'Trade on Arken',
        href: 'https://swap.arken.finance/tokens/bsc/0x0edb3301437b255310ccdcb5be9f736764947696',
        target: '_blank',
      },
      {
        label: 'Liquidity Helper',
        href: '/liquidity',
      },
    ],
  },
  {
    label: 'Earn',
    icon: 'FarmIcon',
    items: [
      {
        label: 'Pools',
        icon: 'PoolIcon',
        href: '/pools',
      },
      {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
      },
    ],
  },

  {
    label: 'HKP',
    icon: 'BuybackIcon',
    href: '/hkp',
  },
  {
    label: 'Jelly',
    icon: 'RoyalJellyIcon',
    items: [
      {
        label: 'Royal Jelly',
        icon: 'RoyalJellyIcon',
        href: '/royaljelly',
      },
      {
        label: 'NFT Jelly',
        icon: 'NftIcon',
        href: '/nftjelly',
      },
    ],
  },

  {
    label: 'Migration',
    icon: 'TicketIcon',
    href: '/migration',
  },
  {
    label: 'Referrals',
    icon: 'GroupsIcon',
    href: '/referrals',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Audit',
        icon: 'AuditIcon',
        href: 'https://solidity.finance/audits/HoneyComb/',
        target: '_blank',
      },
      {
        label: 'Docs',
        icon: 'TicketIcon',
        href: 'https://honeyfarm.gitbook.io/honeycomb',
        target: '_blank',
      },
      {
        label: 'PooCoin',
        href: 'https://poocoin.app/tokens/0x0EdB3301437B255310cCdCb5Be9f736764947696',
        target: '_blank',
      },
      {
        label: 'HoneyBee',
        href: 'https://bee.honeyfarm.finance',
        target: '_blank',
      },
    ],
  },
  //  {
  //    label: 'Audit by Paladin',
  //    icon: 'AuditIcon',
  //    href: 'https://paladinsec.co/projects/honeyfarm-layer-3/',
  //  },
]

export default config
