import { Box, Button, Card, Flex, Input, Slider, Text } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import UnlockButton from 'components/UnlockButton'
import { DEFAULT_TOKEN_DECIMAL, MAIN_TOKEN_SYMBOL } from 'config'
import { usePancakeLPApprove } from 'hooks/useApprove'
import useAddLP, { useAddLPWithCurrency, useRemoveLP, useRemoveLPWithETH } from 'hooks/useLiquidity'
import React, { useCallback, useEffect, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { LP } from 'state/types'
import styled, { css, keyframes } from 'styled-components'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import { BIG_ZERO, BIG_TEN } from 'utils/bigNumber'
import { getCurrentBlockTimestamp } from 'utils/multicall'

const expandAnimation = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 600px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 600px;
  }
  to {
    max-height: 0px;
  }
`

const Container = styled.div<{ expanded }>`
  animation: ${({ expanded }) =>
    expanded
      ? css`
          ${expandAnimation} 300ms linear forwards
        `
      : css`
          ${collapseAnimation} 300ms linear forwards
        `};
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${({ expanded }) => (expanded ? '10px' : 0)};
  .action-header > h2 {
    color: 'text';
    font-size: 25px;
    font-weight: 600;
    line-height: 2;
  }
  .amount > h4 {
    color: 'text';
    font-size: 20px;
    font-weight: 600;
    line-height: 2;
  }
  .token-logo {
    height: 24px;
    width: 24px;
    margin: 0 10px;
  }
  .token-balance {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
    text-align: center;
  }
  .token-symbol,
  .lp-symbol {
    color: 'text';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
  .arrow-down-wrapper svg {
    align-self: center;
    stroke: ${({ theme }) => theme.colors.inputSecondary};
    flex-shrink: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .info-title > p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    margin: 10px 0;
  }
  .info-card {
    padding: 14px;
  }
`
const InputPanel = styled.div`
  padding-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: '20px';
  box-shadow: unset;
  z-index: 1;
`
const InputContainer = styled.div`
  border-radius: 16px;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0;
`
const MaxButton = styled(Button)`
  color: ${({ theme }) => theme.colors.inputSecondary};
  background: unset;
  border: unset;
  box-shadow: unset;
`
const Row = styled(Box)<{
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding ?? '0'};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
const RowBetween = styled(Row)`
  justify-content: space-between;
`
const StyledInput = styled(Input)`
  box-shadow: none;
  margin: 0 8px;
  padding: 0 8px;
  width: 100%;
  background-color: 'input';
`

export interface ICardProps {
  liquidityPool: LP
  tokenAllowance: BigNumber
  quoteTokenAllowance: BigNumber
  lpAllowance: BigNumber
}

const LiquidityCard: React.FC<ICardProps> = (props) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const [requestedSupply, setRequestedSupply] = useState(false)
  const [requestedRemove, setRequestedRemove] = useState(false)
  const { liquidityPool, tokenAllowance, quoteTokenAllowance, lpAllowance } = props
  const { onApprove: tokenApprove } = usePancakeLPApprove(getAddress(liquidityPool.token.address))
  const { onApprove: LPApprove } = usePancakeLPApprove(getAddress(liquidityPool.lpAddresses))
  const { onApprove: quoteTokenApprove } = usePancakeLPApprove(getAddress(liquidityPool.quoteToken.address))
  const { onAddLP: supplyLP } = useAddLP()
  const { onAddLP: supplyLPWithCurrency } = useAddLPWithCurrency()
  const { onRemoveLP: removeLP } = useRemoveLP()
  const { onRemoveLP: removeLPWithETH } = useRemoveLPWithETH()
  const handleTokenApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await tokenApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
      setRequestedApproval(false)
    }
  }, [tokenApprove])
  const handleQuoteTokenApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await quoteTokenApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
      setRequestedApproval(false)
    }
  }, [quoteTokenApprove])
  const handlLPApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await LPApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
      setRequestedApproval(false)
    }
  }, [LPApprove])
  const [isTokenApprove, setIsTokenApprove] = useState(false)
  const [isQuoteTokenApprove, setIsQuoteTokenApprove] = useState(false)
  const [isLPApprove, setIsLPApprove] = useState(false)
  const [expandedAdd, setExpandedAdd] = useState(true)
  const [percent, setPercent] = useState(0)

  const [addTokenAmount, setAddTokenAmount] = useState('0')
  const [addQuoteTokenAmount, setAddQuoteTokenAmount] = useState('0')
  const [addTokenAmountShow, setAddTokenAmountShow] = useState('0')
  const [addQuoteTokenAmountShow, setAddQuoteTokenAmountShow] = useState('0')

  const [addButtonText, setAddButtonText] = useState('Loading..')
  const [removeButtonText, setRemoveButtonText] = useState('Loading..')
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true)
  const [addButtonDisabled, setAddButtonDisabled] = useState(true)

  const { account } = useWeb3React()
  useEffect(() => {
    // set Add button Text, disabled

    if (requestedApproval) {
      setAddButtonText('Wait for Enable...')
      setAddButtonDisabled(true)
    } else if (!isTokenApprove) {
      setAddButtonText(`Enable ${liquidityPool.token.symbol}`)
      setAddButtonDisabled(false)
    } else if (!isQuoteTokenApprove) {
      setAddButtonText(`Enable ${liquidityPool.quoteToken.symbol}`)
      setAddButtonDisabled(false)
    } else if (requestedSupply) {
      setAddButtonText('Wait for supply...')
      setAddButtonDisabled(true)
    } else if (liquidityPool.userData) {
      if (new BigNumber(addTokenAmountShow).isGreaterThan(liquidityPool.userData.tokenBalance)) {
        setAddButtonText(`Insufficient ${liquidityPool.token.symbol} balance `)
        setAddButtonDisabled(true)
      } else if (new BigNumber(addQuoteTokenAmountShow).isGreaterThan(liquidityPool.userData.quoteTokenBalance)) {
        setAddButtonText(`Insufficient ${liquidityPool.quoteToken.symbol} balance `)
        setAddButtonDisabled(true)
      } else {
        setAddButtonText('Supply')
        setAddButtonDisabled(false)
      }
    }

    if (!isLPApprove) {
      setRemoveButtonText('Remove')
      setRemoveButtonDisabled(true)
    } else if (requestedRemove) {
      setRemoveButtonText('Wait..')
      setRemoveButtonDisabled(true)
    } else {
      setRemoveButtonText('Remove')
      setRemoveButtonDisabled(false)
    }
  }, [
    isTokenApprove,
    isQuoteTokenApprove,
    liquidityPool,
    addTokenAmountShow,
    addQuoteTokenAmountShow,
    requestedSupply,
    requestedApproval,
    isLPApprove,
    requestedRemove,
  ])
  const onTokenChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setAddTokenAmountShow(e.currentTarget.value)
        setAddTokenAmount(e.currentTarget.value)
      }
      if (liquidityPool.tokenPriceVsQuote) {
        if (new BigNumber(e.currentTarget.value) === BIG_ZERO || e.currentTarget.value === '') {
          setAddQuoteTokenAmountShow(new BigNumber(0).toFixed(2))
          setAddQuoteTokenAmount(new BigNumber(0).toFixed())
        } else {
          setAddQuoteTokenAmountShow(
            new BigNumber(liquidityPool.tokenPriceVsQuote).times(e.currentTarget.value).toFixed(7),
          )
          setAddQuoteTokenAmount(new BigNumber(liquidityPool.tokenPriceVsQuote).times(e.currentTarget.value).toString())
        }
      }
    },
    [setAddTokenAmountShow, setAddQuoteTokenAmountShow, liquidityPool.tokenPriceVsQuote],
  )
  const onQuoteTokenChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setAddQuoteTokenAmountShow(e.currentTarget.value)
        setAddQuoteTokenAmount(e.currentTarget.value)
      }
      if (liquidityPool.tokenPriceVsQuote) {
        if (new BigNumber(e.currentTarget.value) === BIG_ZERO || e.currentTarget.value === '') {
          setAddTokenAmountShow(new BigNumber(0).toFixed(2))
          setAddTokenAmount(new BigNumber(0).toFixed())
        } else {
          setAddTokenAmountShow(
            new BigNumber(liquidityPool.tokenPriceVsQuote).div(e.currentTarget.value).pow(-1).toFixed(9),
          )
          setAddTokenAmount(
            new BigNumber(liquidityPool.tokenPriceVsQuote).div(e.currentTarget.value).pow(-1).toString(),
          )
        }
      }
    },
    [setAddQuoteTokenAmountShow, setAddTokenAmountShow, liquidityPool.tokenPriceVsQuote],
  )
  const displayBalance = (balance: string) => {
    const balanceNumber = Number(balance)
    if (balanceNumber > 0 && balanceNumber < 0.0001) {
      return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 11 })
    }
    if (balanceNumber < 1) {
      return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 6 })
    }

    if (balanceNumber < 10) {
      return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 4 })
    }
    return balanceNumber.toLocaleString()
  }
  const onSelectTokenMax = () => {
    if (liquidityPool.userData) {
      setAddTokenAmountShow(liquidityPool.userData.tokenBalance)
      setAddTokenAmount(liquidityPool.userData.tokenBalance)
      if (liquidityPool.tokenPriceVsQuote) {
        if (liquidityPool.userData.tokenBalance === '0') {
          setAddQuoteTokenAmountShow(new BigNumber(0).toFixed(2))
          setAddQuoteTokenAmount(new BigNumber(0).toString())
        } else {
          setAddQuoteTokenAmountShow(
            new BigNumber(liquidityPool.userData.tokenBalance).times(liquidityPool.tokenPriceVsQuote).toFixed(7),
          )
          setAddQuoteTokenAmount(
            new BigNumber(liquidityPool.userData.tokenBalance).times(liquidityPool.tokenPriceVsQuote).toString(),
          )
        }
      }
    }
  }
  const onSelectQuoteTokenMax = () => {
    if (liquidityPool.userData) {
      setAddQuoteTokenAmountShow(liquidityPool.userData.quoteTokenBalance)
      setAddQuoteTokenAmount(liquidityPool.userData.quoteTokenBalance)
      if (liquidityPool.tokenPriceVsQuote) {
        if (liquidityPool.userData.quoteTokenBalance === '0') {
          setAddTokenAmountShow(new BigNumber(0).toFixed(2))
          setAddTokenAmount(new BigNumber(0).toString())
        } else {
          setAddTokenAmountShow(
            new BigNumber(liquidityPool.userData.quoteTokenBalance).div(liquidityPool.tokenPriceVsQuote).toFixed(7),
          )
          setAddTokenAmount(
            new BigNumber(liquidityPool.userData.quoteTokenBalance).div(liquidityPool.tokenPriceVsQuote).toString(),
          )
        }
      }
    }
  }
  const onRemove = async () => {
    if (!isLPApprove) {
      return
      // Todo alert to do enable first
    }
    const deadline = new BigNumber(await getCurrentBlockTimestamp()).plus(600).toFixed()
    const tokenADecimal = BIG_TEN.pow(liquidityPool.token.decimals)
    const tokenBDecimal = BIG_TEN.pow(liquidityPool.quoteToken.decimals)
    let isBNBLP = false

    if (getAddress(liquidityPool.quoteToken.address) === getWbnbAddress()) isBNBLP = true
    handleRemoveLP(
      getAddress(liquidityPool.token.address),
      getAddress(liquidityPool.quoteToken.address),
      new BigNumber(percent).times(liquidityPool.userData.lpBalance).times(DEFAULT_TOKEN_DECIMAL).div(100).toFixed(0),
      new BigNumber(percent)
        .times(liquidityPool.userData.tokenBalanceInLP)
        .times(tokenADecimal)
        .div(100)
        .times(0.9)
        .toFixed(0),
      new BigNumber(percent)
        .times(liquidityPool.userData.quoteTokenBalanceInLP)
        .times(tokenBDecimal)
        .div(100)
        .times(0.9)
        .toFixed(0),
      deadline,
      isBNBLP,
    )
  }
  const handleRemoveLP = useCallback(
    async (tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline, isBNBLP) => {
      try {
        setRequestedRemove(true)
        if (isBNBLP) {
          await removeLPWithETH(tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline)
        } else {
          await removeLP(tokenA, tokenB, liquidity, amountAMin, amountBMin, deadline)
        }
        setRequestedRemove(false)
      } catch (e) {
        console.error(e)
        setRequestedRemove(false)
      }
    },
    [removeLP, removeLPWithETH],
  )

  const onSupply = async () => {
    if (!isTokenApprove) {
      handleTokenApprove()
      return
    }
    if (!isQuoteTokenApprove) {
      handleQuoteTokenApprove()
      return
    }
    const deadline = new BigNumber(await getCurrentBlockTimestamp()).plus(99994607).toFixed()
    let isBNBLP = false
    if (getAddress(liquidityPool.quoteToken.address) === getWbnbAddress()) isBNBLP = true
    handleAddLP(
      getAddress(liquidityPool.token.address),
      getAddress(liquidityPool.quoteToken.address),
      addTokenAmount,
      addQuoteTokenAmount,
      new BigNumber(addTokenAmount).times(0.9),
      new BigNumber(addQuoteTokenAmount).times(0.9),
      deadline,
      isBNBLP,
    )
  }
  const handleAddLP = useCallback(
    async (tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, deadline, isBNBLP) => {
      try {
        setRequestedSupply(true)
        const tokenADecimal = BIG_TEN.pow(liquidityPool.token.decimals)
        const tokenBDecimal = BIG_TEN.pow(liquidityPool.quoteToken.decimals)
        if (isBNBLP)
          await supplyLPWithCurrency(
            tokenA,
            new BigNumber(amountADesired).times(tokenADecimal).toFixed(0),
            new BigNumber(amountBDesired).times(tokenBDecimal).toFixed(0),
            new BigNumber(amountAMin).times(tokenADecimal).toFixed(0),
            new BigNumber(amountBMin).times(tokenBDecimal).toFixed(0),
            deadline,
          )
        else {
          await supplyLP(
            tokenA,
            tokenB,
            new BigNumber(amountADesired).times(tokenADecimal).toFixed(0),
            new BigNumber(amountBDesired).times(tokenBDecimal).toFixed(0),
            new BigNumber(amountAMin).times(tokenADecimal).toFixed(0),
            new BigNumber(amountBMin).times(tokenBDecimal).toFixed(0),
            deadline,
          )
        }
        setRequestedSupply(false)
      } catch (e) {
        console.error(e)
        setRequestedSupply(false)
      }
    },
    [supplyLP, supplyLPWithCurrency, liquidityPool],
  )

  const handleChangePercent = (sliderPercent: number) => {
    setPercent(sliderPercent)
  }
  useEffect(() => {
    if (tokenAllowance > BIG_ZERO) setIsTokenApprove(true)
    if (lpAllowance > BIG_ZERO) setIsLPApprove(true)
    if (quoteTokenAllowance > BIG_ZERO) setIsQuoteTokenApprove(true)
  }, [tokenAllowance, quoteTokenAllowance, lpAllowance])
  return (
    <div>
      <Card key={liquidityPool.pid} className="lp-card">
        <Flex>
          <img
            className="lp-logo"
            src={`/images/farms/${liquidityPool.lpSymbol.split(' ')[0].toLowerCase()}.png`}
            alt={liquidityPool.lpSymbol.split(' ')[0]}
          />
          {/* ToDo place image (new import method?) */}
          <p className="lp-symbol">{liquidityPool.lpSymbol}</p>
        </Flex>
        <p>{liquidityPool.userData ? displayBalance(liquidityPool.userData.lpBalance) : 0}</p>
        <Flex className="pooled-token">
          <p>
            Pooled {liquidityPool.token.symbol}:
            {
              /* ToDo find pooled single token amount (calculate?)  */
              liquidityPool.userData ? displayBalance(liquidityPool.userData.tokenBalanceInLP) : 0
            }
          </p>
        </Flex>
        <Flex className="pooled-token">
          <p>
            Pooled {liquidityPool.quoteToken.symbol}:
            {
              /* ToDo find pooled single token amount (calculate?)  */
              liquidityPool.userData ? displayBalance(liquidityPool.userData.quoteTokenBalanceInLP) : 0
            }
          </p>
        </Flex>
        <div className="button-wrapper">
          <Button
            scale="sm"
            onClick={() => {
              setExpandedAdd(true)
            }}
          >
            Add liquidity
          </Button>
          <Button
            scale="sm"
            onClick={() => {
              setExpandedAdd(false)
            }}
          >
            Remove liquidity
          </Button>
        </div>
        <Flex justifyContent="center">
          <div className="divider" />
        </Flex>
        <Container expanded={expandedAdd}>
          <div className="action-header">
            <h2>Add Liquidity</h2>
          </div>
          <Text fontSize="14px" mb={10}>
            Avoid {MAIN_TOKEN_SYMBOL} transfer tax by using the liquidity adding helper.
          </Text>
          <Flex alignContent="center" justifyContent="space-between">
            <Flex>
              <img
                className="token-logo"
                src={`/images/tokens/${liquidityPool.token.symbol.toLowerCase()}.png`}
                alt={liquidityPool.token.symbol}
              />
              <p className="token-symbol">{liquidityPool.token.symbol}</p>
            </Flex>
            <div>
              <p className="token-balance">{`Balance : ${
                liquidityPool.userData ? displayBalance(liquidityPool.userData.tokenBalance) : '-'
              }`}</p>
            </div>
          </Flex>
          <InputPanel>
            <InputContainer>
              <LabelRow>
                <RowBetween>
                  <StyledInput
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    inputMode="decimal"
                    step="any"
                    min="0"
                    onChange={onTokenChange}
                    placeholder="0"
                    value={addTokenAmountShow}
                  />
                  <MaxButton scale="sm" onClick={onSelectTokenMax} mr="8px">
                    Max
                  </MaxButton>
                </RowBetween>
              </LabelRow>
            </InputContainer>
          </InputPanel>
          <Flex justifyContent="center" marginY={20}>
            <Text fontSize="30px" color="inputSecondary">
              +
            </Text>
          </Flex>
          <Flex alignContent="center" justifyContent="space-between">
            <Flex>
              <img
                className="token-logo"
                src={`/images/tokens/${liquidityPool.quoteToken.symbol.toLowerCase()}.png`}
                alt={liquidityPool.quoteToken.symbol}
              />
              <p className="token-symbol">{liquidityPool.quoteToken.symbol}</p>
            </Flex>
            <div>
              <p className="token-balance">{`Balance : ${
                liquidityPool.userData ? displayBalance(liquidityPool.userData.quoteTokenBalance) : '-'
              }`}</p>
            </div>
          </Flex>
          <InputPanel>
            <InputContainer>
              <LabelRow>
                <RowBetween>
                  <StyledInput
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    inputMode="decimal"
                    step="any"
                    min="0"
                    onChange={onQuoteTokenChange}
                    placeholder="0"
                    value={addQuoteTokenAmountShow}
                  />
                  <MaxButton scale="sm" onClick={onSelectQuoteTokenMax} mr="8px">
                    Max
                  </MaxButton>
                </RowBetween>
              </LabelRow>
            </InputContainer>
          </InputPanel>
          {!account ? (
            <UnlockButton width="100%" />
          ) : (
            <Button scale="md" onClick={onSupply} mr="8px" mt="25px" mb="20px" disabled={addButtonDisabled}>
              {addButtonText}
            </Button>
          )}

          <div className="info-title">
            <p>Price and Pool Share</p>
          </div>
          <Card className="info-card">
            <Flex alignContent="center" justifyContent="space-around">
              <div>
                <p className="token-symbol">
                  {displayBalance(new BigNumber(liquidityPool.tokenPriceVsQuote).pow(-1).toFixed())}
                </p>
                <p className="token-balance">
                  {liquidityPool.token.symbol} per <br />
                  {liquidityPool.quoteToken.symbol}
                </p>
              </div>
              <div>
                <p className="token-symbol">
                  {displayBalance(new BigNumber(liquidityPool.tokenPriceVsQuote).toFixed())}
                </p>
                <p className="token-balance">
                  {liquidityPool.quoteToken.symbol} per <br />
                  {liquidityPool.token.symbol}
                </p>
              </div>
              <div>
                <p className="token-symbol">
                  {liquidityPool.userData
                    ? displayBalance(
                        new BigNumber(liquidityPool.userData.lpBalance)
                          .times(DEFAULT_TOKEN_DECIMAL)
                          .div(liquidityPool.lpTotalSupply)
                          .times(100)
                          .toFixed(),
                      )
                    : 0}
                  %
                </p>
                <p className="token-balance">
                  Share of <br /> Pool
                </p>
              </div>
            </Flex>
          </Card>
        </Container>
        <Container expanded={!expandedAdd}>
          <div className="action-header">
            <h2>Remove Liquidity</h2>
          </div>
          <Text fontSize="14px" mb={10}>
            To receive {MAIN_TOKEN_SYMBOL} and {liquidityPool.quoteToken.symbol}
          </Text>
          <div className="amount">
            <h4>Amount</h4>
          </div>
          <div>
            <Slider
              min={0}
              max={100}
              value={percent}
              onValueChanged={handleChangePercent}
              name="stake"
              valueLabel={`${percent}%`}
              step={1}
            />
          </div>
          <Flex justifyContent="center" alignItems="center" className="arrow-down-wrapper">
            <ArrowDown />
          </Flex>
          <div className="info-title">
            <p>YOU WILL RECEIVE</p>
          </div>
          <Card className="info-card">
            <Flex alignContent="center" justifyContent="space-between">
              <Flex>
                <img
                  className="token-logo"
                  src={`/images/tokens/${liquidityPool.token.symbol.toLowerCase()}.png`}
                  alt={liquidityPool.token.symbol}
                />
                <p className="token-symbol">{liquidityPool.token.symbol}</p>
              </Flex>
              <div>
                <p className="token-balance">
                  {liquidityPool.userData
                    ? displayBalance(
                        new BigNumber(liquidityPool.userData.tokenBalanceInLP).times(percent).div(100).toFixed(),
                      )
                    : 0}
                </p>
              </div>
            </Flex>
            <Flex alignContent="center" justifyContent="space-between">
              <Flex>
                <img
                  className="token-logo"
                  src={`/images/tokens/${liquidityPool.quoteToken.symbol.toLowerCase()}.png`}
                  alt={liquidityPool.quoteToken.symbol}
                />
                <p className="token-symbol">{liquidityPool.quoteToken.symbol}</p>
              </Flex>
              <div>
                <p className="token-balance">
                  {liquidityPool.userData
                    ? displayBalance(
                        new BigNumber(liquidityPool.userData.quoteTokenBalanceInLP).times(percent).div(100).toFixed(),
                      )
                    : 0}
                </p>
              </div>
            </Flex>
          </Card>
          <div className="info-title">
            <p>PRICES</p>
          </div>
          <Card className="info-card">
            <Flex alignContent="center" justifyContent="space-between">
              <p className="token-symbol">1 {liquidityPool.quoteToken.symbol} =</p>
              <p className="token-balance">
                {displayBalance(new BigNumber(liquidityPool.tokenPriceVsQuote).pow(-1).toFixed())}{' '}
                {liquidityPool.token.symbol}
              </p>
            </Flex>

            <Flex alignContent="center" justifyContent="space-between">
              <p className="token-symbol">1 {liquidityPool.token.symbol} =</p>
              <p className="token-balance">
                {displayBalance(new BigNumber(liquidityPool.tokenPriceVsQuote).toFixed())}{' '}
                {liquidityPool.quoteToken.symbol}
              </p>
            </Flex>
          </Card>
          {/*
           <Flex>
          <img className='lp-logo' src={`/images/tokens/${liquidityPool.lpSymbol.split(' ')[0].toLowerCase()}.png`} alt={liquidityPool.lpSymbol.split(' ')[0]} />
          <p className='token-symbol'>
            {liquidityPool.lpSymbol}
          </p>
        </Flex>
        */}
          {!account ? (
            <UnlockButton width="100%" />
          ) : (
            <Flex justifyContent="space-around">
              <Button scale="md" onClick={handlLPApprove} mr="8px" mt="25px" disabled={isLPApprove}>
                {isLPApprove ? 'Enabled' : 'Enable'}
              </Button>
              <Button scale="md" onClick={onRemove} mr="8px" mt="25px" disabled={!isLPApprove || removeButtonDisabled}>
                {removeButtonText}
              </Button>
            </Flex>
          )}
        </Container>
      </Card>
    </div>
  )
}

export default LiquidityCard
