/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import poolsConfig from 'config/constants/pools'
import { fetchNFTsBlockLimits, fetchNFTsTotalStaking } from './fetchNFTs'
import {
  fetchNFTsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserLastDepositBlocks,
  fetchUserPendingRewards,
  fetchUserWithdrawalFeeBP,
} from './fetchNFTsUser'
import { NFTsState, NFT } from '../types'

const initialState: NFTsState = { data: [...poolsConfig.filter((poolconfig) => poolconfig.nftImageUrl)] }

export const NFTsSlice = createSlice({
  name: 'NFTs',
  initialState,
  reducers: {
    setNFTsPublicData: (state, action) => {
      const liveNFTsData: NFT[] = action.payload
      state.data = state.data.map((pool) => {
        const liveNFTData = liveNFTsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...liveNFTData }
      })
    },
    setNFTsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userNFTData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userNFTData }
      })
    },
    updateNFTsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)
      state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
    },
  },
})

// Actions
export const { setNFTsPublicData, setNFTsUserData, updateNFTsUserData } = NFTsSlice.actions

// Thunks
export const fetchNFTsPublicDataAsync = () => async (dispatch) => {
  const blockLimits = await fetchNFTsBlockLimits()
  const totalStakings = await fetchNFTsTotalStaking()
  const liveData = poolsConfig
    .filter((poolconfig) => poolconfig.nftImageUrl)
    .map((pool) => {
      const blockLimit = blockLimits.find((entry) => entry.sousId === pool.sousId)
      const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
      return {
        ...blockLimit,
        ...totalStaking,
      }
    })

  dispatch(setNFTsPublicData(liveData))
}

export const fetchNFTsUserDataAsync = (account) => async (dispatch) => {
  const allowances = await fetchNFTsAllowance(account)
  const stakingTokenBalances = await fetchUserBalances(account)
  const stakedBalances = await fetchUserStakeBalances(account)
  const lastDepositBlocks = await fetchUserLastDepositBlocks(account)
  const withdrawalFeeBPs = await fetchUserWithdrawalFeeBP(account)
  const pendingRewards = await fetchUserPendingRewards(account)

  const userData = poolsConfig
    .filter((poolconfig) => poolconfig.nftImageUrl)
    .map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      lastDepositBlock: lastDepositBlocks[pool.sousId],
      withdrawalFeeBP: withdrawalFeeBPs[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))

  dispatch(setNFTsUserData(userData))
}

export const updateUserAllowance = (sousId: string, account: string) => async (dispatch) => {
  const allowances = await fetchNFTsAllowance(account)
  dispatch(updateNFTsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
}

export const updateUserBalance = (sousId: string, account: string) => async (dispatch) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(updateNFTsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
}

export const updateUserStakedBalance = (sousId: string, account: string) => async (dispatch) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(updateNFTsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
}

export const updateUserPendingReward = (sousId: string, account: string) => async (dispatch) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(updateNFTsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
}

export default NFTsSlice.reducer
