import BigNumber from 'bignumber.js'
import React from 'react'
import { CardBody, Flex, Text, CardRibbon, Heading, Skeleton } from '@pancakeswap-libs/uikit'
import UnlockButton from 'components/UnlockButton'
import useI18n from 'hooks/useI18n'
import { useLpTokenPrice, useBusdPriceFromToken } from 'state/hooks'
import { Pool } from 'state/types'
import CountDown from 'react-countdown'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { getPoolApr } from 'utils/apr'
import { BIG_ZERO } from 'utils/bigNumber'
import AprRow from './AprRow'
import StyledCard from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'

const PoolCard: React.FC<{ pool: Pool; account: string; path: string; finishedPool?: boolean }> = ({
  pool,
  account,
  finishedPool,
}) => {
  const {
    sousId,
    stakingToken,
    earningToken,
    isFinished,
    userData,
    nftLogoUrl,
    nftPrice,
    rewardPerSecond,
    totalStaked,
  } = pool
  const TranslateString = useI18n()
  const stakedBalance = new BigNumber(userData?.stakedBalance || 0)
  const accountHasStakedBalance = stakedBalance?.toNumber() > 0
  const lpPrice = useLpTokenPrice(stakingToken.symbol)
  const tokenPrice = useBusdPriceFromToken(stakingToken.symbol) || BIG_ZERO

  const earnLpPrice = useLpTokenPrice(earningToken.symbol)
  const earnTokenPrice = useBusdPriceFromToken(earningToken.symbol)
  const stakingTokenPrice = stakingToken.isLP ? lpPrice.toNumber() : tokenPrice.toNumber()
  const earningTokenPrice = earningToken.isLP ? earnLpPrice.toNumber() : earnTokenPrice.toNumber()
  const apr = getPoolApr(
    stakingTokenPrice,
    earningTokenPrice,
    getBalanceNumber(totalStaked, stakingToken.decimals),
    new BigNumber(rewardPerSecond).div(10 ** 18).toNumber(),
  )
  return (
    <StyledCard
      isStaking={!isFinished && accountHasStakedBalance}
      isFinished={isFinished && sousId !== 0}
      finishedPool={finishedPool}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={`${TranslateString(388, 'Finished')}`} />}
    >
      <StyledCardHeader
        earningTokenSymbol={earningToken.symbol}
        stakingTokenSymbol={stakingToken.symbol}
        isFinished={isFinished && sousId !== 0}
        nftLogoUrl={nftLogoUrl}
        nftPrice={nftPrice}
        title={earningToken.title}
        titleLink={earningToken.titleLink}
      />
      <CardBody>
        <AprRow pool={pool} stakingTokenPrice={stakingTokenPrice} />
        <Flex mt="24px" flexDirection="column">
          {account ? (
            <CardActions
              pool={pool}
              stakedBalance={stakedBalance}
              stakingTokenPrice={stakingTokenPrice}
              accountHasStakedBalance={accountHasStakedBalance}
            />
          ) : (
            <>
              <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                {TranslateString(999, 'Start earning')}
              </Text>
              <UnlockButton />
            </>
          )}
        </Flex>
        <NFTInfo>
          <Heading mb={10} fontSize="20px" color="text" fontFamily="'PT Sans', sans-serif;" fontWeight={700}>
            Lock Info
          </Heading>
          <Flex justifyContent="space-between">
            <Text style={{ fontSize: '16px' }}>Status</Text>
            <Text bold style={{ fontSize: '16px' }}>
              {Date.now() < (new BigNumber(pool?.userData?.nextWithdrawalUntil).toNumber() * 1000 ?? 0)
                ? 'Locked'
                : 'Unlocked'}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text style={{ fontSize: '16px' }}>Your APR</Text>
            <Text bold style={{ fontSize: '16px' }}>
              {new BigNumber(pool?.userData?.lockDuration ?? 0).isZero()
                ? '- %'
                : `${new BigNumber(pool.userData.lockDuration)
                    .times(pool.maxIncentive)
                    .div(pool.maxInterval * 10000)
                    .plus(1)
                    .times(apr)
                    .toFixed(2)}%`}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text style={{ fontSize: '16px' }}>Lock duration</Text>
            <Text bold style={{ fontSize: '16px' }}>
              {`${new BigNumber(pool?.userData?.lockDuration ?? 0).div(86400).toFixed(0)} days`}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text style={{ fontSize: '16px' }}>Remaining lock duration</Text>
            <Text bold style={{ fontSize: '16px' }}>
              <CountDown
                date={new Date(new BigNumber(pool?.userData?.nextWithdrawalUntil).toNumber() * 1000 ?? 0)}
                intervalDelay={999}
                renderer={renderer}
              />
            </Text>
          </Flex>
        </NFTInfo>
      </CardBody>
      <CardFooter pool={pool} account={account} stakingTokenSymbol={pool.stakingToken.symbol} />
    </StyledCard>
  )
}

const NFTInfo = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const renderer = ({ hours, minutes, seconds, days }) => {
  // Render a countdown
  return (
    <span>{Number.isNaN(seconds) ? <Skeleton width={60} /> : `${days}d ${hours}h ${minutes}min ${seconds}s `}</span>
  )
}

export default PoolCard
