import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

interface multicallI {
  address: string
  name: string
  params?: Array<number | string>
}

interface fetchPoolsI {
  sousId: number
  startBlock?: string
  endBlock?: string
  maxInterval?: string
  maxIncentive?: string
  totalRewarded?: string
  rewardPerSecond?: string
  totalStaked?: string
}
export const fetchPools = async (): Promise<fetchPoolsI[][]> => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0).filter((p) => !p.nftImageUrl)
  const sousChefMC2DArray: multicallI[][] = poolsWithEnd.map((poolConfig) => {
    return [
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'startTimestamp',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'bonusEndTimestamp',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'maxMaxWithdrawalInterval',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'incentiveRateForMaxWithdrawalLock',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'totalRewardedTokanAmount',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'rewardPerSecond',
      },
    ]
  })
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB').filter((p) => !p.nftImageUrl)
  const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB').filter((p) => !p.nftImageUrl)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const ercMC2DArray: multicallI[][] = [callsBnbPools, callsNonBnbPools]

  const sousChefMC: multicallI[] = [].concat(...sousChefMC2DArray)
  const ercMC: multicallI[] = [].concat(...ercMC2DArray)
  const [sousChefMCResult, ercMCResult] = await Promise.all([
    multicall(sousChefABI, sousChefMC),
    multicall(erc20ABI, ercMC),
  ]).catch((error) => {
    console.log(sousChefMC)
    console.log(ercMC)
    throw new Error(`multicall nontoken: ${error}`)
  })

  return [
    poolsWithEnd.map((cakePoolConfig, index) => {
      const startBlock = sousChefMCResult[index * 6]
      const endBlock = sousChefMCResult[index * 6 + 1]
      const maxInterval = sousChefMCResult[index * 6 + 2]
      const maxIncentive = sousChefMCResult[index * 6 + 3]
      const totalRewardedTokanAmounts = sousChefMCResult[index * 6 + 4]
      const poolRewardPerSecond = sousChefMCResult[index * 6 + 5]
      return {
        sousId: cakePoolConfig.sousId,
        startBlock: new BigNumber(startBlock).toJSON(),
        endBlock: new BigNumber(endBlock).toJSON(),
        maxInterval: new BigNumber(maxInterval).toJSON(),
        maxIncentive: new BigNumber(maxIncentive).toJSON(),
        totalRewarded: new BigNumber(totalRewardedTokanAmounts).toJSON(),
        rewardPerSecond: new BigNumber(poolRewardPerSecond).toJSON(),
      }
    }),
    [...bnbPool, ...nonBnbPools].map((pool, index) => {
      return { sousId: pool.sousId, totalStaked: new BigNumber(ercMCResult[index]).toJSON() }
    }),
  ]
}

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0).filter((p) => !p.nftImageUrl)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startTimestamp',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndTimestamp',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)
  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}
export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB').filter((p) => !p.nftImageUrl)
  const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB').filter((p) => !p.nftImageUrl)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}
export const fetchPoolsLockInfo = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0).filter((p) => !p.nftImageUrl)
  const maxMaxWithdrawalInterval = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'maxMaxWithdrawalInterval',
    }
  })
  const incentiveRateForMaxWithdrawalLock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'incentiveRateForMaxWithdrawalLock',
    }
  })
  const totalRewardedTokanAmount = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalRewardedTokanAmount',
    }
  })
  const rewardPerSecond = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'rewardPerSecond',
    }
  })

  const maxIntervals = await multicall(sousChefABI, maxMaxWithdrawalInterval)
  const maxIncentives = await multicall(sousChefABI, incentiveRateForMaxWithdrawalLock)
  const rewardPerSeconds = await multicall(sousChefABI, rewardPerSecond)
  const totalRewardedTokanAmounts = await multicall(sousChefABI, totalRewardedTokanAmount)
  return poolsWithEnd.map((cakePoolConfig, index) => {
    const maxInterval = maxIntervals[index]
    const maxIncentive = maxIncentives[index]
    const poolRewardPerSecond = rewardPerSeconds[index]
    return {
      sousId: cakePoolConfig.sousId,
      maxInterval: new BigNumber(maxInterval).toJSON(),
      maxIncentive: new BigNumber(maxIncentive).toJSON(),
      totalRewarded: new BigNumber(totalRewardedTokanAmounts).toJSON(),
      rewardPerSecond: new BigNumber(poolRewardPerSecond).toJSON(),
    }
  })
}
