import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // RoyalJelly
  {
    sousId: 27,
    // Todo: sousID
    stakingToken: tokens.comb,
    earningToken: tokens.busd,
    contractAddress: {
      97: '',
      56: '0x4C75355118757E0D961FAF5c9Dbd72274E10e198',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    rewardPerSecond: '0.00617573',
    sortOrder: 1,
    isFinished: false,
  },
  // NFTJelly
  {
    sousId: 105,
    stakingToken: tokens.comb,
    earningToken: tokens.honey_nfh_1,
    contractAddress: {
      97: '',
      56: '0x89e1F75bb3BA2A1E2c4B5b6121EDaCC54d38347c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    rewardPerSecond: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: 'images/pools/beanz.png',
    nftImageUrl: '/images/pools/bean9018.png',
    nftPrice: '',
    depositFee: '2% FEE',
    minDeposit: 0,
    size: 'lg',
  },
  // {
  //   sousId: 112,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.honey_nfh_8,
  //   contractAddress: {
  //     97: '',
  //     56: '0x100187903167cCc0E434c2552Fd55eE0417b2Af7',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: 'images/pools/metasaurs_logo.png',
  //   nftImageUrl: '/images/pools/metasaurs_3695.png',
  //   nftPrice: '$1,500',
  //   depositFee: '0.1 BEE',
  //   minDeposit: 1,
  //   size: 'sm',
  // },
  // {
  //   sousId: 110,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.honey_nfh_6,
  //   contractAddress: {
  //     97: '',
  //     56: '0x45F91d28aC399c8ccF764368900531575E4d0Cd5',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: '',
  //   nftImageUrl: '/images/pools/yolonaut.png',
  //   nftPrice: '$2,000',
  //   depositFee: '0.05 BEE',
  //   minDeposit: 0.5,
  //   size: 'sm',
  // },
  // {
  //   sousId: 111,
  //   stakingToken: tokens.bear,
  //   earningToken: tokens.honey_nfh_7,
  //   contractAddress: {
  //     97: '',
  //     56: '0x416c59a2daaC8cC3e7c704C6F26016Ee07328E03',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: '',
  //   nftImageUrl: '/images/pools/yoloverseloyalty.png',
  //   nftPrice: '$600',
  //   depositFee: '0.5 BEAR',
  //   minDeposit: 5,
  //   size: 'sm',
  // },
  // {
  //   sousId: 107,
  //   stakingToken: tokens.moon,
  //   earningToken: tokens.honey_nfh_2,
  //   contractAddress: {
  //     97: '',
  //     56: '0x92A278552D34623c87A9958E1eDb552060e950f0',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: 'images/pools/metasaurs_logo.png',
  //   nftImageUrl: '/images/pools/metasaurs_5471.png',
  //   nftPrice: '$2,000',
  //   depositFee: '0.5 MOON',
  //   minDeposit: 5,
  //   size: 'sm',
  // },
  // {
  //   sousId: 106,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.honey_nfh_3,
  //   contractAddress: {
  //     97: '',
  //     56: '0xF1F9AE0e4c31E661661FFFFaB0fd7f198c79fD34',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: 'images/pools/metasaurs_logo.png',
  //   nftImageUrl: '/images/pools/metasaurs_3696.png',
  //   nftPrice: '$8,600',
  //   depositFee: '0.05 BEE',
  //   minDeposit: 0.5,
  //   size: 'sm',
  // },
  // {
  //   sousId: 108,
  //   stakingToken: tokens.honey,
  //   earningToken: tokens.honey_nfh_4,
  //   contractAddress: {
  //     97: '',
  //     56: '0x1fdcC057084e75b259FE6abD0d03770bE682d795',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: '',
  //   nftImageUrl: '/images/pools/klaypunks_2922.png',
  //   nftPrice: '$320',
  //   depositFee: '0.5 HONEY',
  //   minDeposit: 5,
  //   size: 'sm',
  // },
  // {
  //   sousId: 109,
  //   stakingToken: tokens.bear,
  //   earningToken: tokens.honey_nfh_5,
  //   contractAddress: {
  //     97: '',
  //     56: '0xD018a16856De25995D68F9c9Fa2E8DDd83C955f7',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   nftLogoUrl: '',
  //   nftImageUrl: '/images/pools/klaypunks_2793.png',
  //   nftPrice: '$400',
  //   depositFee: '0.5 BEAR',
  //   minDeposit: 5,
  //   size: 'sm',
  // },

  // // RoyalJelly
  // {
  //   sousId: 34,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.busd,
  //   contractAddress: {
  //     97: '',
  //     56: '0x9044916Ee8195c2CceFFDbb57C3c1Bc2A46EE9C7',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '0.0260416',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 33,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.btcb,
  //   contractAddress: {
  //     97: '',
  //     56: '0xFe1264a9D69EB1f005724a94CF689523a05e6b61',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '0.0000006943',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 32,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.btcb,
  //   contractAddress: {
  //     97: '',
  //     56: '0x15e9932806991e6d5c3Ff7e3998DDAAD35F82Af4',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '0.00000055628',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 31,
  //   stakingToken: tokens.bee,
  //   earningToken: tokens.eth,
  //   contractAddress: {
  //     97: '',
  //     56: '0xA5801d9affb1798C5838Ec8e28B8C02F3872BB56',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   rewardPerSecond: '0.00000557',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
]

export default pools
