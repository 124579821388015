import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import poolsReducer from './pools'
import vaultsReducer from './vaults'
import predictionsReducer from './predictions'
import profileReducer from './profile'
import teamsReducer from './teams'
import achievementsReducer from './achievements'
import blockReducer from './block'
import collectiblesReducer from './collectibles'
import buybackReducer from './buyback'
import referralReducer from './referral'
import liquidityReducer from './liquidity'
import PricesReducer from './prices'
import LpRewardsReducer from './lprewards'
import NFTsReducer from './nfts'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    achievements: achievementsReducer,
    block: blockReducer,
    farms: farmsReducer,
    pools: poolsReducer,
    predictions: predictionsReducer,
    profile: profileReducer,
    teams: teamsReducer,
    prices: PricesReducer,
    collectibles: collectiblesReducer,
    liquidityPools: liquidityReducer,
    buyback: buybackReducer,
    referral: referralReducer,
    vaults: vaultsReducer,
    lpRewards: LpRewardsReducer,
    nfts: NFTsReducer,
  },
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
