import React from 'react'
import { Flex, Text, IconButton, useModal, CalculateIcon, Skeleton, useTooltip } from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import { getBalanceNumber } from 'utils/formatBalance'
import { getPoolApr } from 'utils/apr'
import { tokenEarnedPerThousandDollarsCompounding, getRoi } from 'utils/compoundApyHelpers'
import { useLpTokenPrice, useBusdPriceFromToken } from 'state/hooks'
import Balance from 'components/Balance'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { Pool } from 'state/types'
import { BASE_EXCHANGE_URL } from 'config'
import BigNumber from 'bignumber.js'

interface AprRowProps {
  pool: Pool
  stakingTokenPrice: number
  isAutoVault?: boolean
  compoundFrequency?: number
}

const AprRow: React.FC<AprRowProps> = ({ pool, stakingTokenPrice, isAutoVault = false, compoundFrequency = 1 }) => {
  const TranslateString = useI18n()
  const { stakingToken, earningToken, totalStaked, isFinished, rewardPerSecond } = pool

  const lpPrice = useLpTokenPrice(earningToken.symbol)
  const tokenPrice = useBusdPriceFromToken(earningToken.symbol)
  const earningTokenPrice = earningToken.isLP ? lpPrice.toNumber() : tokenPrice.toNumber()

  const apr = getPoolApr(
    stakingTokenPrice,
    earningTokenPrice,
    getBalanceNumber(totalStaked, stakingToken.decimals),
    new BigNumber(rewardPerSecond).div(10 ** 18).toNumber(),
  )
  // // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
  const isHighValueToken = Math.round(earningTokenPrice / 1000) > 0
  const roundingDecimals = isHighValueToken ? 4 : 2

  const apyModalLink =
    stakingToken.address &&
    `${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${stakingToken.address[process.env.REACT_APP_CHAIN_ID]}`

  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPrice}
      apr={apr}
      linkLabel={`${TranslateString(999, 'Get')} ${stakingToken.symbol}`}
      linkHref={apyModalLink || BASE_EXCHANGE_URL}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={isHighValueToken ? 4 : 2}
      compoundFrequency={compoundFrequency}
    />,
  )
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontSize="16px">{isAutoVault ? TranslateString(999, 'APY') : TranslateString(736, 'APR')}:</Text>
      {isFinished || !apr ? (
        <Skeleton width="82px" height="32px" />
      ) : (
        <Flex alignItems="center">
          <Balance
            fontSize="16px"
            isDisabled={isFinished}
            value={new BigNumber(apr).toNumber()}
            /*
              lowest apr boost
              new BigNumber(pool.maxIncentive)
              .times(3 * 60) // todo revert 60 to 86400
              .div(pool.maxInterval)
              .div(10000)
              .times(apr)
              .plus(apr)
              .toNumber()
              */
            decimals={2}
            unit="%"
            bold
          />
          <Text ml={1} mr={1}>
            ~
          </Text>
          <Balance
            fontSize="16px"
            isDisabled={isFinished}
            value={new BigNumber(pool.maxIncentive).div(10000).times(apr).plus(apr).toNumber()}
            decimals={2}
            unit="%"
            bold
          />
          {/*  <IconButton onClick={onPresentApyModal} variant="text" scale="sm">
            <CalculateIcon color="textSubtle" width="18px" />
          </IconButton> */}
        </Flex>
      )}
    </Flex>
  )
}

export default AprRow
