import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract';
import { useAppDispatch } from 'state'
import { fetchBuybackUserDataAsync } from 'state/actions';
import { approveBuyback } from 'utils/callHelpers';
import { useBuyback } from './useContract';

// Approve buyback
const useApprove = (sellTokenContract: Contract) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const buybackContract = useBuyback();
  const handleApprove = useCallback(async () => {
    try {
      const tx = await approveBuyback(sellTokenContract, buybackContract, account);
      dispatch(fetchBuybackUserDataAsync(account));
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, sellTokenContract, buybackContract]);

  return { onApprove: handleApprove };
};

export default useApprove;
