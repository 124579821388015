import poolsConfig from 'config/constants/pools'
import nftABI from 'config/abi/nftJelly.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

export const fetchNFTsBlockLimits = async () => {
  const NFTsWithEnd = poolsConfig.filter((p) => p.nftImageUrl)
  const callsStartBlock = NFTsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startTimestamp',
    }
  })
  const callsEndTimestamp = NFTsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusendTimestamp',
    }
  })
  const starts = await multicall(nftABI, callsStartBlock)
  const ends = await multicall(nftABI, callsEndTimestamp)
  return NFTsWithEnd.map((cakePoolConfig, index) => {
    const startTimestamp = starts[index]
    const endTimestamp = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startTimestamp: new BigNumber(startTimestamp).toJSON(),
      endTimestamp: new BigNumber(endTimestamp).toJSON(),
    }
  })
}

export const fetchNFTsTotalStaking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB').filter((p) => p.nftImageUrl)
  const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB').filter((p) => p.nftImageUrl)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}
