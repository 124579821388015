import React, { useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Link, Button } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import usePersistState from 'hooks/usePersistState'
import { useNFTs } from 'state/hooks'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import PageHeader from 'components/PageHeader'
import NFTCard from 'views/NFT/components/NFTCard'
import PoolTabButtons from 'views/Pools/components/PoolTabButtons'
import useRefresh from 'hooks/useRefresh'

const PoolPage = styled(Page)`
  margin: 0px;
  max-width: 100vw;
  padding: 0px 0px 200px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0px 0px 500px 0px;
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const NFTFlexLayout = styled(FlexLayout)`
  & > * {
    max-width: 480px;
  }
`

const NFTs: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWeb3React()
  const nfts = useNFTs(account)
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now() / 1000)
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    setCurrentTimestamp(Date.now() / 1000)
  }, [fastRefresh])
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'pancake_pool_staked_nft')
  const [finishedPools, openPools] = useMemo(
    () =>
      partition(
        nfts,
        (nft) =>
          nft.isFinished ||
          (currentTimestamp > Number(nft.endTimestamp) + (24 * 60 * 60) / 3 && Number(nft.endTimestamp) > 0),
      ),
    [currentTimestamp, nfts],
  )

  const stakedOnlyPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools],
  )
  const hasStakeInFinishedPools = useMemo(
    () => finishedPools.some((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [finishedPools],
  )

  return (
    <>
      <PageHeader>
        <Heading as="h1" size="xl" color="textMenu" mb="14px" textAlign="center">
          NFT Jelly
        </Heading>
        <Heading size="md" color="textMenu" textAlign="center">
          Stake COMB to earn NFTs!
        </Heading>
        <Heading size="md" color="textMenu" pb="10px" textAlign="center">
          You can unstake at any time.
        </Heading>
        {/* extra */}
        <Wrapper>
          <Link external href="https://honeyfarm.gitbook.io/honeycomb/products/nft-jelly">
            <Button style={{ borderRadius: 100 }}>Learn More</Button>
          </Link>
        </Wrapper>
      </PageHeader>
      <PoolPage>
        <PoolTabButtons
          stakedOnly={stakedOnly}
          setStakedOnly={setStakedOnly}
          hasStakeInFinishedPools={hasStakeInFinishedPools}
          isNft
        />
        <NFTFlexLayout>
          {stakedOnly
            ? orderBy(stakedOnlyPools, ['sortOrder']).map((pool) => (
                <NFTCard key={pool.sousId} pool={pool} account={account} path={path} />
              ))
            : orderBy(openPools, ['sortOrder']).map((pool) => (
                <NFTCard key={pool.sousId} pool={pool} account={account} path={path} />
              ))}
          {orderBy(finishedPools, ['sortOrder']).map((pool) => (
            <NFTCard key={pool.sousId} pool={pool} account={account} path={path} finishedPool />
          ))}
        </NFTFlexLayout>
      </PoolPage>
    </>
  )
}

export default NFTs
