import tokens from './tokens'
import { LPConfig } from './types'

const liquidityPools: LPConfig[] = [
  /*
  {
    pid: 47,
    lpSymbol: 'tCOMB-BNB',
    decimals: 18,
    lpAddresses: {
      97: '',
      56: '0xb2D1CBb388e57aC3E0B3F4440E7F304588F4e3e3',
    },
    token: tokens.tcomb,
    quoteToken: tokens.wbnb,
  },

  */
  {
    pid: 48,
    lpSymbol: 'COMB-BUSD',
    decimals: 18,
    lpAddresses: {
      97: '',
      56: '0x567B5B5f64d134d7ae21450D9ea5f72AB64566Ea',
    },
    token: tokens.comb,
    quoteToken: tokens.busd,
  },
]
export default liquidityPools
