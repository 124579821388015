import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Text,
  Flex,
  Button,
  Slider,
  BalanceInput,
  AutoRenewIcon,
  Link,
  IconButton,
  MinusIcon,
  Input,
  AddIcon,
} from '@pancakeswap-libs/uikit'
import useI18n from 'hooks/useI18n'
import { BASE_EXCHANGE_URL } from 'config'
import { useSousStake } from 'hooks/useStake'
import { useSousUnstake } from 'hooks/useUnstake'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import BigNumber from 'bignumber.js'
import { getFullDisplayBalance, formatNumber, getDecimalAmount, getBalanceNumber } from 'utils/formatBalance'
import { Pool } from 'state/types'
import { useBusdPriceFromToken, useLpTokenPrice } from 'state/hooks'
import { getPoolApr } from 'utils/apr'
import { getAddress } from 'utils/addressHelpers'
import PercentageButton from './PercentageButton'

interface StakeModalProps {
  isBnbPool: boolean
  pool: Pool
  stakingMax: BigNumber
  stakingTokenPrice: number
  isRemovingStake?: boolean
  onDismiss?: () => void
  isNft?: boolean
}

const StyledLink = styled(Link)`
  width: 100%;
`
const Duration = styled(Flex)`
  & > button {
    width: 30px;
    border: unset;
  }
  & > p {
    color: white;
  }
`
const StakeModal: React.FC<StakeModalProps> = ({
  pool,
  stakingMax,
  stakingTokenPrice,
  isRemovingStake = false,
  onDismiss,
  isNft = false,
}) => {
  const { sousId, stakingToken, earningToken, userData, depositFee, minDeposit, totalStaked, rewardPerSecond } = pool
  const { withdrawalFeeBP, lastDepositBlock, lockDuration, nextWithdrawalUntil } = userData
  const TranslateString = useI18n()
  const { theme } = useTheme()
  const { onStake } = useSousStake(sousId)
  const { onUnstake } = useSousUnstake(sousId)
  const { toastSuccess, toastError } = useToast()
  const calculratedMinInterval = Math.ceil(
    new BigNumber(nextWithdrawalUntil)
      .minus(Date.now() / 1000)
      .div(86400)
      .toNumber(),
  ) // todo revert to 86400
  const minInterval = calculratedMinInterval < 0 ? 0 : calculratedMinInterval
  const [duration, setDuration] = useState(minInterval)
  const [pendingTx, setPendingTx] = useState(false)
  const [stakeAmount, setStakeAmount] = useState('')
  const [percent, setPercent] = useState(0)
  const maxInterval = new BigNumber(pool?.maxInterval ?? 30 * 86400).div(86400).toNumber() // todo revert 60 to 86400
  const usdValueStaked = stakeAmount && formatNumber(new BigNumber(stakeAmount).times(stakingTokenPrice).toNumber())

  const earnLpPrice = useLpTokenPrice(earningToken.symbol)
  const earnTokenPrice = useBusdPriceFromToken(earningToken.symbol)
  const earningTokenPrice = earningToken.isLP ? earnLpPrice.toNumber() : earnTokenPrice.toNumber()
  const apr = getPoolApr(
    stakingTokenPrice,
    earningTokenPrice,
    getBalanceNumber(totalStaked, stakingToken.decimals),
    new BigNumber(rewardPerSecond).div(10 ** 18).toNumber(),
  )

  const displayWithdrawFee = withdrawalFeeBP ? new BigNumber(withdrawalFeeBP).div(100).toLocaleString() : '0'
  const displayLastDepositBlock = lastDepositBlock

  const handleStakeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value || '0'
    const convertedInput = getDecimalAmount(new BigNumber(inputValue), stakingToken.decimals)
    const percentage = Math.floor(convertedInput.dividedBy(stakingMax).multipliedBy(100).toNumber())
    setStakeAmount(inputValue)
    setPercent(Math.min(percentage, 100))
  }

  const handleChangePercent = (sliderPercent: number) => {
    const percentageOfStakingMax = stakingMax.dividedBy(100).multipliedBy(sliderPercent)
    const amountToStake = getFullDisplayBalance(percentageOfStakingMax, stakingToken.decimals, stakingToken.decimals)
    setStakeAmount(amountToStake)
    setPercent(sliderPercent)
  }
  const setMax = () => {
    setDuration(maxInterval)
  }
  const handleConfirmClick = async () => {
    setPendingTx(true)

    if (isRemovingStake) {
      // unstaking
      try {
        await onUnstake(stakeAmount, stakingToken.decimals)
        toastSuccess(
          `${TranslateString(999, 'Unstaked')}!`,
          TranslateString(999, `Your ${earningToken.symbol} earnings have also been harvested to your wallet!`),
        )
        setPendingTx(false)
        onDismiss()
      } catch (e) {
        toastError(
          TranslateString(999, 'Canceled'),
          TranslateString(999, 'Please try again and confirm the transaction.'),
        )
        setPendingTx(false)
      }
    } else {
      try {
        // staking
        await onStake(stakeAmount, stakingToken.decimals, duration * 86400) // contract duration unit is sec  // Todo revert to  * 86400
        toastSuccess(
          `${TranslateString(1074, 'Staked')}!`,
          TranslateString(999, `Your ${stakingToken.symbol} funds have been staked in the pool!`),
        )
        setPendingTx(false)
        onDismiss()
      } catch (e) {
        toastError(
          TranslateString(999, 'Canceled'),
          TranslateString(999, 'Please try again and confirm the transaction.'),
        )
        setPendingTx(false)
      }
    }
  }

  return (
    <Modal
      title={isRemovingStake ? TranslateString(588, 'Unstake') : TranslateString(999, 'Stake in Pool')}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      {!isNft && isRemovingStake && (
        <>
          <Text>Withdrawal fee: {displayWithdrawFee}%</Text>
          {displayWithdrawFee !== '0' && <Text>until block number: {displayLastDepositBlock}</Text>}
        </>
      )}
      {isNft && (
        <>
          {isRemovingStake ? (
            <>
              <Text>
                {TranslateString(800, `${displayWithdrawFee}% withdrawal fee will be charged for withdraw before`)}
              </Text>
              <Text
                onClick={() => {
                  window.open(`https://bscscan.com/block/${pool.endBlock}`)
                }}
                mb="16px"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {TranslateString(802, 'end block')}
              </Text>
            </>
          ) : (
            <>
              <Text mb="16px">
                {TranslateString(800, `${depositFee} will be charged for deposit fee to prevent abuse.`)}
              </Text>
              <Text mb="16px">
                {TranslateString(801, `Stake at least ${minDeposit} ${stakingToken.symbol} to participate.`)}
              </Text>
            </>
          )}
        </>
      )}

      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>{isRemovingStake ? TranslateString(588, 'Unstake') : TranslateString(316, 'Stake')}:</Text>

        <Flex alignItems="center" minWidth="70px">
          <img
            src={`/images/farms/${stakingToken.symbol.toLowerCase()}.png`}
            width={30}
            height={30}
            alt={stakingToken.symbol}
          />
          <Text ml="4px" bold>
            {stakingToken.symbol}
          </Text>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between">
        <Text bold style={{ fontSize: '16px' }}>
          Your APR:
        </Text>
        <Text bold style={{ fontSize: '16px' }}>
          {/* todo revert to 86400 */}
          {`${new BigNumber(duration * 86400)
            .times(pool.maxIncentive)
            .div(pool.maxInterval * 10000)
            .plus(1)
            .times(apr)
            .toFixed(2)}%`}
        </Text>
      </Flex>
      {!isRemovingStake && !isNft && (
        <Flex alignItems="center" justifyContent="space-between" mb="8px">
          <Text bold>Lock duration (days):</Text>

          <Duration alignItems="center" minWidth="70px">
            <IconButton
              variant="secondary"
              onClick={() => {
                if (duration > 0) {
                  setDuration(duration <= minInterval ? minInterval : duration - 1)
                }
              }}
              mr="6px"
            >
              <MinusIcon color="primary" width="24px" />
            </IconButton>
            <div style={{ width: '80px' }}>
              <Input
                type="number"
                value={duration}
                onChange={(e) => {
                  setDuration(parseInt(e.target.value))
                }}
              />
            </div>
            <IconButton
              variant="secondary"
              onClick={() => {
                setDuration(duration + 1 > maxInterval ? maxInterval : duration + 1)
              }}
            >
              <AddIcon color="primary" width="24px" height="24px" />
            </IconButton>
            <Button
              variant="primary"
              height={30}
              ml={2}
              onClick={() => {
                setMax()
              }}
            >
              <p>max</p>
            </Button>
          </Duration>
        </Flex>
      )}
      <BalanceInput
        value={stakeAmount}
        onChange={handleStakeInputChange}
        currencyValue={`~${usdValueStaked || 0} USD`}
      />
      <Text mt="8px" ml="auto" color="textSubtle" fontSize="12px" mb="8px">
        Balance: {getFullDisplayBalance(stakingMax, stakingToken.decimals)}
      </Text>
      <Slider
        min={0}
        max={100}
        value={percent}
        onValueChanged={handleChangePercent}
        name="stake"
        valueLabel={`${percent}%`}
        step={1}
      />
      <Flex alignItems="center" justifyContent="space-between" mt="8px">
        <PercentageButton onClick={() => handleChangePercent(25)}>25%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(50)}>50%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(75)}>75%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(100)}>MAX</PercentageButton>
      </Flex>
      <Button
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
        onClick={handleConfirmClick}
        disabled={
          !stakeAmount ||
          parseFloat(stakeAmount) === 0 ||
          (isNft && !isRemovingStake && Number(stakeAmount) < Number(minDeposit))
        }
        mt="24px"
      >
        {pendingTx ? TranslateString(802, 'Confirming') : TranslateString(464, 'Confirm')}
      </Button>
      {!isRemovingStake && (
        <StyledLink external href={`${BASE_EXCHANGE_URL}/swap?outputCurrency=${getAddress(stakingToken.address)}`}>
          <Button width="100%" mt="8px" variant="secondary">
            {TranslateString(999, 'Get')} {stakingToken.symbol}
          </Button>
        </StyledLink>
      )}
    </Modal>
  )
}

export default StakeModal
