import { useContext } from 'react'
import { isEmpty } from 'lodash'
import { TranslationsContext } from '../contexts/Localisation/translationsContext'

interface ContextData {
  [key: string]: number | string
}

const useI18n = () => {
  return (translationId: number, fallback: string, data: ContextData = {}) => {
    return fallback
  }
}

export default useI18n
