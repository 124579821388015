/* eslint-disable spaced-comment */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import lpConfig from 'config/constants/liquidityPool'
import { fetchUserLPsBalance } from './fetchLPsUser'
import { LPState, LP } from '../types'

const noAccountLPConfig = lpConfig

const initialState: LPState = { data: noAccountLPConfig, userDataLoaded: false }

export const liquidityPoolSlice = createSlice({
  name: 'LPs',
  initialState,
  reducers: {
    setLPsUserData: (state, action) => {
      const liveLPsData: LP[] = action.payload.map((payload) => {
        return {
          ...payload,
        }
      })
      state.data = state.data.map((lp) => {
        const liveLPData = liveLPsData.find((f) => f.pid === lp.pid)
        return { ...lp, ...liveLPData }
      })
      state.userDataLoaded = true
    },
  },
})

// Actions
export const { setLPsUserData } = liquidityPoolSlice.actions

// Thunks
export const fetchLPsUserDataAsync = (account: string) => async (dispatch) => {
  const userLPsBalance = await fetchUserLPsBalance(account, lpConfig)

  dispatch(setLPsUserData(userLPsBalance))
}

export default liquidityPoolSlice.reducer
