/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LpRewardApiResponse, LpRewardApiThunk, LpRewardsState } from 'state/types'

const initialState: LpRewardsState = {
  data: [],
}
// Thunks
export const fetchLpRewards = createAsyncThunk<LpRewardApiThunk>('lpRewards/fetch', async () => {
  const response = await fetch(
    'https://raw.githubusercontent.com/pancakeswap/pancake-frontend/develop/src/config/constants/lpAprs/56.json',
  )
  // const response2 = await fetch('https://api.pancakeswap.info/api/v2/summary')
  const data = (await response.json()) as LpRewardApiResponse
  // const data2 = (await response2.json()) as SummaryApiResponse

  // Return normalized token names
  return {
    data: Object.keys(data).map((pancakeLpAddress) => {
      return {
        pancakeLpAddress,
        lpReward: data[pancakeLpAddress],
      }
    }, {}),
  }
})

export const lpRewardsSlice = createSlice({
  name: 'lpRewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLpRewards.fulfilled, (state, action: PayloadAction<LpRewardApiThunk>) => {
      state.data = action.payload.data
    })
  },
})

export default lpRewardsSlice.reducer
