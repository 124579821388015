import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { useCookies } from 'react-cookie'
import { fetchFarmUserDataAsync, updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stake, sousStake, sousStakeNFT } from 'utils/callHelpers'
import { useMasterchef, useSousChef, useKingdom, useSousNFTChef } from './useContract'

const useStake = (pid: number, isKingdom?: boolean) => {
  const [cookies] = useCookies(['referral_code'])
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()
  const kingdomContract = useKingdom()
  let referralCode = '0x0000000000000000000000000000000000000000'
  if (cookies.referral_code !== undefined) {
    referralCode = atob(cookies.referral_code)
  }

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(isKingdom ? kingdomContract : masterChefContract, pid, amount, account, referralCode)
      dispatch(fetchFarmUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, masterChefContract, pid, kingdomContract, isKingdom, referralCode],
  )

  return { onStake: handleStake }
}
export const useSousNFTStake = (sousId) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useSousNFTChef(sousId)

  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      //      if (sousId === 0) {
      //        await stake(masterChefContract, 0, amount, account, referralCode)
      //      } else if (isUsingBnb) {
      //        await sousStakeBnb(sousChefContract, amount, account)
      //      } else {
      await sousStakeNFT(sousChefContract, amount, decimals, account)
      //      }
      dispatch(updateUserStakedBalance(sousId, account))
      dispatch(updateUserBalance(sousId, account))
    },
    [account, dispatch, sousChefContract, sousId],
  )

  return { onStake: handleStake }
}

export const useSousStake = (sousId) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useSousChef(sousId)

  const handleStake = useCallback(
    async (amount: string, decimals: number, lockDuration: number) => {
      //      if (sousId === 0) {
      //        await stake(masterChefContract, 0, amount, account, referralCode)
      //      } else if (isUsingBnb) {
      //        await sousStakeBnb(sousChefContract, amount, account)
      //      } else {
      await sousStake(sousChefContract, amount, lockDuration, decimals, account)
      //      }
      dispatch(updateUserStakedBalance(sousId, account))
      dispatch(updateUserBalance(sousId, account))
    },
    [account, dispatch, sousChefContract, sousId],
  )

  return { onStake: handleStake }
}

export default useStake
