// import contracts from './contracts'
import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 20,
    lpSymbol: 'COMB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x567B5B5f64d134d7ae21450D9ea5f72AB64566Ea',
    },
    token: tokens.comb,
    quoteToken: tokens.busd,
    plusApr: 0,
  },
  {
    pid: 0,
    lpSymbol: 'BTCB-ETH',
    lpAddresses: {
      97: '',
      56: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
    },
    token: tokens.btcb,
    quoteToken: tokens.eth,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 1,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 2,
    lpSymbol: 'BTCB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 3,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 4,
    lpSymbol: 'ETH-USDC',
    lpAddresses: {
      97: '',
      56: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
    },
    token: tokens.eth,
    quoteToken: tokens.usdc,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 5,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 6,
    lpSymbol: 'CAKE-BUSD',
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 7,
    lpSymbol: 'CAKE-USDT',
    lpAddresses: {
      97: '',
      56: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
    },
    token: tokens.cake,
    quoteToken: tokens.usdt,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 8,
    lpSymbol: 'USDT-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 9,
    lpSymbol: 'USDC-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 10,
    lpSymbol: 'TUSD-BUSD',
    lpAddresses: {
      97: '',
      56: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
    },
    token: tokens.tusd,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 11,
    lpSymbol: 'USDC-USDT',
    lpAddresses: {
      97: '',
      56: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdt,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 12,
    lpSymbol: 'DAI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 13,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 14,
    lpSymbol: 'USDT-BNB',
    lpAddresses: {
      97: '',
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 15,
    lpSymbol: 'COMB',
    lpAddresses: {
      97: '',
      56: '0x567B5B5f64d134d7ae21450D9ea5f72AB64566Ea',
    },
    token: tokens.comb,
    quoteToken: tokens.busd,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 16,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 17,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 18,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  /*
  {
    pid: 1,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    isPancake: true,
    plusApr: 0,
  },
  {
    pid: 2,
    lpSymbol: 'tCOMB-BNB',
    lpAddresses: {
      97: '',
      56: '0xb2D1CBb388e57aC3E0B3F4440E7F304588F4e3e3',
    },
    token: tokens.tcomb,
    quoteToken: tokens.wbnb,
    plusApr: 0,
  },

  {
    pid: 3,
    lpSymbol: 'BNB',
    lpAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    plusApr: 0,
    isTokenOnly: true,
  },

  {
    pid: 4,
    lpSymbol: 'tCOMB',
    lpAddresses: {
      97: '',
      56: '0xb2D1CBb388e57aC3E0B3F4440E7F304588F4e3e3',
    },
    token: tokens.tcomb,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },

 {
   pid: 47,
   lpSymbol: 'BEE-BNB',
   lpAddresses: {
     97: '',
     56: '0x5c80860f8e48d0e32b21d280043dd7b08a1061fb',
   },
   token: tokens.bee,
   quoteToken: tokens.wbnb,
   plusApr: 15.46,
 },
 {
   pid: 48,
   lpSymbol: 'BEE-BUSD',
   lpAddresses: {
     97: '',
     56: '0xcC9D680071A52F2710f9b3344dc34bF644fc4EcF',
   },
   token: tokens.bee,
   quoteToken: tokens.busd,
   plusApr: 14.54,
 },
  {
    pid: 25,
    lpSymbol: 'BEE',
    lpAddresses: {
      97: '',
      56: '0x5c80860f8e48d0e32b21d280043dd7b08a1061fb',
    },
    token: tokens.bee,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
    isBoosted: true
  },
  
  
  {
    pid: 2,
    lpSymbol: 'MOON-BNB',
    lpAddresses: {
      97: '',
      56: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
    },
    token: tokens.moon,
    quoteToken: tokens.wbnb,
    plusApr: 3.39,
  },
 {
   pid: 74,
   lpSymbol: 'DEP-BNB',
   lpAddresses: {
     97: '',
     56: '0x6518709dC42F70Dbf89934cA382F1d9dB26370e8',
   },
   token: tokens.dep,
   quoteToken: tokens.wbnb,
   plusApr: 0,
 },
 {
   pid: 66,
   lpSymbol: 'LINK-BNB',
   lpAddresses: {
     97: '',
     56: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
   },
   token: tokens.link,
   quoteToken: tokens.wbnb,
   plusApr: 11.97,
 },
 {
   pid: 64,
   lpSymbol: 'DOT-BNB',
   lpAddresses: {
     97: '',
     56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
   },
   token: tokens.dot,
   quoteToken: tokens.wbnb,
   plusApr: 13.17,
 },
 {
   pid: 49,
   lpSymbol: 'ADA-BNB',
   lpAddresses: {
     97: '',
     56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
   },
   token: tokens.ada,
   quoteToken: tokens.wbnb,
   plusApr: 12.18,
 },
  {
    pid: 3,
    lpSymbol: 'BTCB-ETH',
    lpAddresses: {
      97: '',
      56: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
    },
    token: tokens.btcb,
    quoteToken: tokens.eth,
    plusApr: 3.58,
  },
  {
    pid: 4,
    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
    plusApr: 14.20,
  },
  {
    pid: 5,
    lpSymbol: 'BTCB-BUSD',
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
    plusApr: 11.87,
  },
  {
    pid: 6,
    lpSymbol: 'ETH-BNB',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    plusApr: 12.73,
  },
  {
    pid: 7,
    lpSymbol: 'ETH-USDC',
    lpAddresses: {
      97: '',
      56: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
    },
    token: tokens.eth,
    quoteToken: tokens.usdc,
    plusApr: 10.74,
  },
  {
    pid: 8,
    lpSymbol: 'CAKE-BNB',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    plusApr: 5.11,
  },
 {
   pid: 9,
   lpSymbol: 'CAKE-BUSD',
   lpAddresses: {
     97: '',
     56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
   },
   token: tokens.cake,
   quoteToken: tokens.busd,
   plusApr: 17.79,
 },
 {
   pid: 10,
   lpSymbol: 'CAKE-USDT',
   lpAddresses: {
     97: '',
     56: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
   },
   token: tokens.cake,
   quoteToken: tokens.usdt,
   plusApr: 27.43,
 },
  {
    pid: 11,
    lpSymbol: 'USDT-BUSD',
    lpAddresses: {
      97: '',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
    plusApr: 8.82,
  },
 {
   pid: 12,
   lpSymbol: 'USDC-BUSD',
   lpAddresses: {
     97: '',
     56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
   },
   token: tokens.usdc,
   quoteToken: tokens.busd,
   plusApr: 7.58,
 },
 {
   pid: 13,
   lpSymbol: 'TUSD-BUSD',
   lpAddresses: {
     97: '',
     56: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
   },
   token: tokens.tusd,
   quoteToken: tokens.busd,
   plusApr: 1.87,
 },
 {
   pid: 14,
   lpSymbol: 'USDC-USDT',
   lpAddresses: {
     97: '',
     56: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
   },
   token: tokens.usdc,
   quoteToken: tokens.usdt,
   plusApr: 3.89,
 },
  {
    pid: 15,
    lpSymbol: 'DAI-BUSD',
    lpAddresses: {
      97: '',
      56: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
    },
    token: tokens.dai,
    quoteToken: tokens.busd,
    plusApr: 8.81,
  },
 {
   pid: 16,
   lpSymbol: 'BNB-BUSD',
   lpAddresses: {
     97: '',
     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
   },
   token: tokens.wbnb,
   quoteToken: tokens.busd,
   plusApr: 33.09,
 },
 
 {
   pid: 17,
   lpSymbol: 'USDT-BNB',
   lpAddresses: {
     97: '',
     56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
   },
   token: tokens.usdt,
   quoteToken: tokens.wbnb,
   plusApr: 36.11,
 },
 {
   pid: 46,
   lpSymbol: 'BELT-BNB',
   lpAddresses: {
     97: '',
     56: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
   },
   token: tokens.belt,
   quoteToken: tokens.wbnb,
   plusApr: 1.15,
 },
 {
   pid: 19,
   lpSymbol: 'RINI-BNB',
   lpAddresses: {
     97: '',
     56: '0x5F188bEDe05D11b8d1474D6832b41d3EaE4ED98E',
   },
   token: tokens.rini,
   quoteToken: tokens.wbnb,
   plusApr: 39.57,
 },
  {
    pid: 20,
    lpSymbol: 'CHERRY-BNB',
    lpAddresses: {
      97: '',
      56: '0x3fa0da1e8bc26ccd1e579d0ae59fa409a7e30ddf',
    },
    token: tokens.cherry,
    quoteToken: tokens.wbnb,
    plusApr: 4.15,
  },
   {
     pid: 21,
     lpSymbol: 'WIZARD-BNB',
     lpAddresses: {
       97: '',
       56: '0x791c4b25e5250971d5fe8b0cbe87b836aec7cbf1',
     },
     token: tokens.wizard,
     quoteToken: tokens.wbnb,
     plusApr: 2.12,
   },
 {
   pid: 22,
   lpSymbol: 'SING-BUSD',
   lpAddresses: {
     97: '',
     56: '0xdDdc245c62a0AA875893Ab53b52455aCdCd7f526',
   },
   token: tokens.sing,
   quoteToken: tokens.busd,
   plusApr: 0,
 },
 {
   pid: 23,
   lpSymbol: 'BABY-USDT',
   lpAddresses: {
     97: '',
     56: '0xe730c7b7470447ad4886c763247012dfd233baff',
   },
   token: tokens.baby,
   quoteToken: tokens.usdt,
   plusApr: 0,
 },
 {
   pid: 24,
   lpSymbol: 'RBS-BNB',
   lpAddresses: {
     97: '',
     56: '0x3374d4a7df0bfa44bcc5aa9a304b23c60ff33d84',
   },
   token: tokens.rbs,
   quoteToken: tokens.wbnb,
   plusApr: 3.34,
 },
  {
    pid: 45,
    lpSymbol: 'BOB-BNB',
    lpAddresses: {
      97: '',
      56: '0x2F4E6454ba7bA0102f426C188d0B32034476D0dF',
    },
    token: tokens.bob,
    quoteToken: tokens.wbnb,
    plusApr: 0,
  },
  
  
  {
    pid: 26,
    lpSymbol: 'MOON',
    lpAddresses: {
      97: '',
      56: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
    },
    token: tokens.moon,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 27,
    lpSymbol: 'BEAR',
    lpAddresses: {
      97: '',
      56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    },
    token: tokens.bear,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 28,
    lpSymbol: 'HONEY',
    lpAddresses: {
      97: '',
      56: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
    },
    token: tokens.honey,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 29,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 30,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 31,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  {
    pid: 32,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    isTokenOnly: true,
  },
  
  
  // Deprecated
  {
    pid: 18,
    lpSymbol: 'BELT-BNB',
    lpAddresses: {
      97: '',
      56: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
    },
    token: tokens.belt,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    multiplier: '0X'
  },
  {
    pid: 65,
    lpSymbol: 'XRP-BNB',
    lpAddresses: {
      97: '',
      56: '0x03f18135c44c64ebfdcbad8297fe5bdafdbbdd86',
    },
    token: tokens.xrp,
    quoteToken: tokens.wbnb,
    plusApr: 0,
    multiplier: '0X'
  },
 {
   pid: 67,
   lpSymbol: 'SQUID-BNB',
   lpAddresses: {
     97: '',
     56: '0x2e0484D3684701dC032f29cce59c785A5837B34E',
   },
   token: tokens.squid,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   multiplier: '0X'
 },
 {
   pid: 68,
   lpSymbol: 'BTT-BUSD',
   lpAddresses: {
     97: '',
     56: '0xdcfbB12DED3FEa12D2A078Bc6324131cD14bF835',
   },
   token: tokens.btt,
   quoteToken: tokens.busd,
   plusApr: 0,
   multiplier: '0X'
 },
 {
   pid: 69,
   lpSymbol: 'AVAX-BNB',
   lpAddresses: {
     97: '',
     56: '0x40aFc7CBd0Dc2bE5860F0035b717d20Afb4827b2',
   },
   token: tokens.avax,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   multiplier: '0X'
 },
 {
   pid: 72,
   lpSymbol: 'PACOCA-BNB',
   lpAddresses: {
     97: '',
     56: '0x0fee6e1e55fa772fae71e6734a7f9e8622900d75',
   },
   token: tokens.pacoca,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   multiplier: '0X'
 },
 {
   pid: 73,
   lpSymbol: 'LTC-BNB',
   lpAddresses: {
     97: '',
     56: '0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC',
   },
   token: tokens.ltc,
   quoteToken: tokens.wbnb,
   plusApr: 0,
   multiplier: '0X'
 },
   */

  /* inactive */
  // {
  //   pid: 50,
  //   lpSymbol: 'ADA-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
  //   },
  //   token: tokens.ada,
  //   quoteToken: tokens.wbnb,
  //   plusApr: 0,
  //   isNew: true,
  // },
  //  {
  //    pid: 47,
  //    lpSymbol: 'DOT-BNB',
  //    lpAddresses: {
  //      97: '',
  //      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
  //    },
  //    token: tokens.dot,
  //    quoteToken: tokens.wbnb,
  //    plusApr: 0,
  //  },
  // {
  //   pid: 45,
  //   lpSymbol: 'LINK-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
  //   },
  //   token: tokens.link,
  //   quoteToken: tokens.wbnb,
  //   plusApr: 4.98,
  // },
  // {
  //   pid: 43,
  //   lpSymbol: 'YAG-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x36a0c612F986a0b15E665F574d971bAD71998977',
  //   },
  //   token: tokens.yag,
  //   quoteToken: tokens.wbnb,
  //   plusApr: 23.23,
  // },
  // {
  //   pid: 21,
  //   lpSymbol: 'NABOX-BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x29b4abb0f8734ea672a0e82fa47998f710b6a07a',
  //   },
  //   token: tokens.nabox,
  //   quoteToken: tokens.busd,
  //   plusApr: 10.77,
  // },

  /*
   {
     pid: 35,
     lpSymbol: 'QBT-BNB',
     lpAddresses: {
       97: '',
       56: '0x67efef66a55c4562144b9acfcfbc62f9e4269b3e',
     },
     token: tokens.qbt,
     quoteToken: tokens.wbnb,
     plusApr: 0,
     multiplier: '0X' 
   },
   {
     pid: 36,
     lpSymbol: 'FARA-BNB',
     lpAddresses: {
       97: '',
       56: '0xffd50f49ad4f9335abc1f1b9e5a6ed90815f5fda',
     },
     token: tokens.fara,
     quoteToken: tokens.wbnb,
     plusApr: 0,
     multiplier: '0X'
   },
   {
     pid: 37,
     lpSymbol: 'POTS-BNB',
     lpAddresses: {
       97: '',
       56: '0xf90baa331cfd40f094476e752bf272892170d399',
     },
     token: tokens.pots,
     quoteToken: tokens.busd,
     plusApr: 0,
     multiplier: '0X'
   },
   {
     pid: 38,
     lpSymbol: 'CHESS-USDC',
     lpAddresses: {
       97: '',
       56: '0x1472976e0b97f5b2fc93f1fff14e2b5c4447b64f',
     },
     token: tokens.chess,
     quoteToken: tokens.usdc,
     plusApr: 0,
     multiplier: '0X'
   },
   {
     pid: 39,
     lpSymbol: 'C98-BNB',
     lpAddresses: {
       97: '',
       56: '0x92247860a03f48d5c6425c7ca35cdcfcb1013aa1',
     },
     token: tokens.c98,
     quoteToken: tokens.wbnb,
     plusApr: 0,
     multiplier: '0X'
   },
    {
      pid: 42,
      lpSymbol: 'ZOON-BNB',
      lpAddresses: {
        97: '',
        56: '0x5407d635315514cfb438a8cac2c72cce890631da',
      },
      token: tokens.zoon,
      quoteToken: tokens.wbnb,
      plusApr: 0,
      multiplier: '0X'
    },
    {
      pid: 44,
      lpSymbol: 'EROS-BNB',
      lpAddresses: {
        97: '',
        56: '0x35bEF07CC5F9babD66dF2C979300dcE35926bb77',
      },
      token: tokens.eros,
      quoteToken: tokens.wbnb,
      plusApr: 0,
      multiplier: '0X' 
    },
     {
       pid: 49,
       lpSymbol: 'FTM-BNB',
       lpAddresses: {
         97: '',
         56: '0x47A0B7bA18Bb80E4888ca2576c2d34BE290772a6',
       },
       token: tokens.ftm,
       quoteToken: tokens.wbnb,
       plusApr: 0,
       multiplier: '0X'
     },
     {
       pid: 51,
       lpSymbol: 'PEAR-BNB',
       lpAddresses: {
         97: '',
         56: '0x36649058bccf2b8855eed1ce44adb734028c6f8d',
       },
       token: tokens.pear,
       quoteToken: tokens.wbnb,
       plusApr: 0,
       multiplier: '0X'
     },
*/
]

export default farms
