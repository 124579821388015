import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Heading, Flex, Link, Button } from '@pancakeswap-libs/uikit'
import { useLPs } from 'state/hooks'
import useRefresh from 'hooks/useRefresh'
import { fetchLPsUserDataAsync } from 'state/actions'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import PageHeader from 'components/PageHeader'
import LiquidityCard from './components/LiquidityCard'

const LiquidityPage = styled.div`
  .liquidity-page {
    .liquidity-page > div:first-child {
      height: 140px;
    }
  }
  padding-bottom: 300px;
  .page-contents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .lp-card {
      min-width: 350px;
      max-width: 450px;
      margin: 20px;
      padding: 20px;
      img.lp-logo {
        height: 49px;
        width: auto;
        margin: 0 10px;
      }
      .lp-symbol {
        color: ${({ theme }) => theme.colors.text};
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
      }
      .button-wrapper > button {
        margin: 10px 10px;
      }
      & > p {
        color: ${({ theme }) => theme.colors.text};
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-left: 4px;
      }
      .divider {
        margin-top: 15px;
        width: 80%;
        height: 2px;
        background: grey;
      }
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const Liquidity: React.FC = () => {
  const { data: LPs, userDataLoaded } = useLPs()
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (account) {
      dispatch(fetchLPsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])
  return (
    <LiquidityPage className="liquidity-page">
      <PageHeader>
        <Heading as="h1" size="xl" color="textMenu" mb="14px" textAlign="center">
          Liquidity Helper
        </Heading>
        <Heading size="md" color="textMenu" mb="14px" textAlign="center">
          Add liquidity and remove liquidity without TAX!
        </Heading>
        {/* extra */}
        <Wrapper>
          {/* todo: liquidity docslink  */}
          <Link external href="https://honeyfarm.gitbook.io/honeycomb/tokenomics/liquidity-helper">
            <Button style={{ borderRadius: 100 }}>Learn More</Button>
          </Link>
        </Wrapper>
      </PageHeader>
      <Flex className="page-contents">
        {LPs.map((lp) => (
          <LiquidityCard
            key={lp.pid}
            liquidityPool={lp}
            tokenAllowance={userDataLoaded ? new BigNumber(lp.userData.tokenAllowance) : BIG_ZERO}
            quoteTokenAllowance={userDataLoaded ? new BigNumber(lp.userData.quoteTokenAllowance) : BIG_ZERO}
            lpAllowance={userDataLoaded ? new BigNumber(lp.userData.lpAllowance) : BIG_ZERO}
          />
        ))}
      </Flex>
    </LiquidityPage>
  )
}

export default Liquidity
