import BigNumber from 'bignumber.js'
import buybackABI from 'config/abi/buyback.json'
import bep20Abi from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getBuybackAddress, getCakeAddress } from 'utils/addressHelpers'

const fetchBuybackUserAccount = async (account: string) => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'userInfo',
      params: [account],
    },
  ]

  const mainTokenCalls = [
    {
      address: getCakeAddress(),
      name: 'allowance',
      params: [account, getBuybackAddress()],
    },
    {
      address: getCakeAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]

  const userInfo = await multicall(buybackABI, calls)
  const tokenUserInfo = await multicall(bep20Abi, mainTokenCalls)
  return {
    appliedAmount: new BigNumber(userInfo[0].appliedAmount._hex).toNumber(),
    buybackedAmount: new BigNumber(userInfo[0].buybackedAmount._hex).toNumber(),
    lastAppliedTimestamp: new BigNumber(userInfo[0].lastAppliedTimestamp._hex).toNumber(),
    allowance: new BigNumber(tokenUserInfo[0]).toNumber(),
    tokenBalance: new BigNumber(tokenUserInfo[1].balance._hex).toFixed(),
  }
}
export default fetchBuybackUserAccount
