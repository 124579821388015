import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import kingdomsABI from 'config/abi/kingdoms.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getKingdomsAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'

interface multicallI {
  address: string
  name: string
  params?: Array<number | string>
}
const fetchFarmUser = async (account: string, farmsToFetch: FarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()
  const erc20MC2DArray: multicallI[][] = farmsToFetch.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? getAddress(farm.token.address) : getAddress(farm.lpAddresses)
    return [
      { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] },
      {
        address: lpContractAddress,
        name: 'balanceOf',
        params: [account],
      },
    ]
  })
  const marsterChefMC2DArray: multicallI[][] = farmsToFetch.map((farm) => {
    return [
      {
        address: masterChefAddress,
        name: 'userInfo',
        params: [farm.pid, account],
      },
      {
        address: masterChefAddress,
        name: 'pendingTokens',
        params: [farm.pid, account],
      },
      {
        address: masterChefAddress,
        name: 'getWithdrawFee',
        params: [farm.pid, account],
      },
    ]
  })

  const erc20MC: multicallI[] = [].concat(...erc20MC2DArray)
  const marsterChefMC: multicallI[] = [].concat(...marsterChefMC2DArray)
  const [erc20MCResult, marsterChefMCResult] = await Promise.all([
    multicall(erc20ABI, erc20MC),
    multicall(masterchefABI, marsterChefMC),
  ])
  const parsedLpAllowances = erc20MCResult.reduce((acc, lpAllowance, i) => {
    if (i % 2 === 0) {
      acc.push(new BigNumber(lpAllowance).toJSON())
    }
    return acc
  }, [])
  const parsedTokenBalances = erc20MCResult.reduce((acc, lpBalance, i) => {
    if (i % 2 === 1) {
      acc.push(new BigNumber(lpBalance).toJSON())
    }
    return acc
  }, [])
  const parsedStakedBalances = marsterChefMCResult.reduce((acc, userInfo, i) => {
    if (i % 3 === 0) {
      acc.push(new BigNumber(userInfo[0]._hex).toJSON())
    }
    return acc
  }, [])
  const parsedEarnings = marsterChefMCResult.reduce((acc, pendingTokens, i) => {
    if (i % 3 === 1) {
      acc.push(
        pendingTokens[0].map((address, index) => {
          return { address, earning: pendingTokens[1][index].toString() }
        }),
      )
    }
    return acc
  }, [])
  const parsedWithdrawFeeBPs = marsterChefMCResult.reduce((acc, withdrawFeeBP, i) => {
    if (i % 3 === 2) {
      acc.push(new BigNumber(withdrawFeeBP).toJSON())
    }
    return acc
  }, [])
  const parsedLastDepositBlocks = marsterChefMCResult.reduce((acc, userInfo, i) => {
    if (i % 3 === 0) {
      acc.push(new BigNumber(userInfo[2]._hex).toJSON())
    }
    return acc
  }, [])

  return [
    parsedLpAllowances,
    parsedTokenBalances,
    parsedStakedBalances,
    parsedEarnings,
    parsedWithdrawFeeBPs,
    parsedLastDepositBlocks,
  ]
}
export default fetchFarmUser
