import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchLPsUserDataAsync } from 'state/actions'
import { addLiquidity, addLiquidityWithCurrency, removeLiquidity, removeLiquidityWithETH } from 'utils/callHelpers'
import { usePancakeLP } from './useContract'

const useAddLP = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pancakeLPContract = usePancakeLP()

  const handleAddLP = useCallback(
    async (
      tokenA: string,
      tokenB: string,
      amountADesired: string,
      amountBDesired: string,
      amountAMin: string,
      amountBMin: string,
      deadline: string,
    ) => {
      const txHash = await addLiquidity(
        pancakeLPContract,
        tokenA,
        tokenB,
        amountADesired,
        amountBDesired,
        amountAMin,
        amountBMin,
        account,
        deadline,
      )
      dispatch(fetchLPsUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, pancakeLPContract],
  )

  return { onAddLP: handleAddLP }
}
export const useAddLPWithCurrency = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pancakeLPContract = usePancakeLP()

  const handleAddLP = useCallback(
    async (
      tokenA: string,
      amountADesired: string,
      amountBDesired: string,
      amountAMin: string,
      amountBMin: string,
      deadline: string,
    ) => {
      const txHash = await addLiquidityWithCurrency(
        pancakeLPContract,
        tokenA,
        amountADesired,
        amountBDesired,
        amountAMin,
        amountBMin,
        account,
        deadline,
      )
      dispatch(fetchLPsUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, pancakeLPContract],
  )

  return { onAddLP: handleAddLP }
}
export const useRemoveLP = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pancakeLPContract = usePancakeLP()

  const handleRemoveLP = useCallback(
    async (
      tokenA: string,
      tokenB: string,
      liquidity: string,
      amountAMin: string,
      amountBMin: string,
      deadline: string,
    ) => {
      const txHash = await removeLiquidity(
        pancakeLPContract,
        tokenA,
        tokenB,
        liquidity,
        amountAMin,
        amountBMin,
        account,
        deadline,
      )
      dispatch(fetchLPsUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, pancakeLPContract],
  )

  return { onRemoveLP: handleRemoveLP }
}

export const useRemoveLPWithETH = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pancakeLPContract = usePancakeLP()

  const handleRemoveLP = useCallback(
    async (
      tokenA: string,
      tokenB: string,
      liquidity: string,
      amountAMin: string,
      amountBMin: string,
      deadline: string,
    ) => {
      const txHash = await removeLiquidityWithETH(
        pancakeLPContract,
        tokenA,
        tokenB,
        liquidity,
        amountAMin,
        amountBMin,
        account,
        deadline,
      )
      dispatch(fetchLPsUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, pancakeLPContract],
  )

  return { onRemoveLP: handleRemoveLP }
}

export default useAddLP
